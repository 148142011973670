
import axios from 'axios';
import { getAuthHeaders, graphQlUrl } from '../utilities';

const appId = process.env.REACT_APP_APP_ID || "1";

export const getMotionsForUser = async (userId, token) => {
  try {
    const query = `
      query MyQuery {
        motionByUser(userId: "${userId}") {
          motionId
          caseId
          userId
          appId
          s3Url
          stage
          timestamp
        }
      }
    `
    console.log("graphQlUrl", graphQlUrl);
    console.log("query", query);
      const response = await axios.post(graphQlUrl, { query });    
      return {
        success: true,
        data: response.data.data
      }; 
  
    } catch (error) {
      console.error('Error getting motions:', error);
      return {
        success: false,
        message: error.message
      };
    }
}


export const getMotionByCaseAndUser = async (caseId, userId) => {
  try {
    const query = `
      query MyQuery {
        motionByCaseAndUser(caseId: "${caseId}", userId: "${userId}") {
          motionId
          caseId
          userId
          appId
          S3Url
          fileName
          stage
          timestamp
        }
      }
    `
      const response = await axios.post(graphQlUrl, { query });    
      return {
        success: true,
        data: response?.data?.data?.motionByCaseAndUser
      }; 
  
    } catch (error) {
      console.error('Error getting MotionByCaseAndUser:', error);
      return {
        success: false,
        message: error.message
      };
    }
}
