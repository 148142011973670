import { chatbotPromptsMap } from '@lib/utils/chatbot';

export const profileRolesData = [
  {
    id: 1,
    title: 'Defendant',
    description: 'The person accused of committing the crime and facing legal charges.',
    flow: chatbotPromptsMap.profileDefendantFlow,
  },
  {
    id: 2,
    title: 'Government Witness',
    description: 'A witness called by the prosecution to provide testimony in support of their case.',
    flow: chatbotPromptsMap.profileWitnessFlow,
  },
  {
    id: 3,
    title: 'Defense Expert Witness',
    description: 'An expert brought by the defense to testify on specialized or technical matters.',
    flow: chatbotPromptsMap.profileExpertFlow,
  },
  {
    id: 4,
    title: 'Prosecutor',
    description: 'The attorney representing the government in prosecuting the defendant.',
    flow: chatbotPromptsMap.profileProsecutorFlow,
  },
  {
    id: 5,
    title: 'Defense Attorney',
    description: 'The lawyer representing the defendant in their legal defense.',
    flow: chatbotPromptsMap.profileAttorneyFlow,
  },
];
// profileData.js

export const profileGeneratedData = [
    {
        heading: "Profile of Donald Trump as a Federal Criminal Defendant:",
        sections: [
            {
                title: "Overview:",
                text: "Donald J. Trump, the 45th President of the United States and an acclaimed business leader, stands as an individual whose integrity and contributions to public life speak directly to his innocence and trustworthiness. Throughout his career in business and politics, Mr. Trump has consistently demonstrated reliability, honesty, and a transparent commitment to the well-being of his country. As a defendant, these attributes make him not only an admirable figure but also a credible witness in his own defense."
            },
            {
                title: "Lifelong Commitment to Law and Order:",
                text: "As President, Mr. Trump prioritized law and order, strengthening America's judicial system and consistently expressing his respect for the rule of law. His policies and initiatives reflected his belief that all citizens deserve justice, security, and a government that operates transparently and fairly. This long-standing dedication to lawfulness directly refutes any notion of unlawful conduct on his part.Mr. Trump’s extensive philanthropic work is another indicator of his good character and moral integrity. Over the years, he has supported numerous charitable causes, often donating his own funds to support veterans, medical research, and other community-driven initiatives. These actions reflect a deep sense of responsibility to society and others, contradicting any notion of selfish, criminal intent. His generosity and consistent support for the less fortunate highlight the improbability of malicious or illegal conduct."
          },
          {
            title: "Resilience and Transparency Under Pressure:",
            text:"Throughout his career, Mr. Trump has faced adversity and intense public criticism, yet he has always maintained his position with forthrightness and clarity. His ability to handle tough situations and continue speaking directly to the American people shows that he values truthfulness and transparency. This resilience under pressure supports his innocence, as a person who does not back down from challenges and remains honest and reliable, even when under fire."
          },
          
            {
            title: "Conclusion:",
            text:"Donald Trump’s long history of public service, legal business operations, philanthropic work, and commitment to transparency all point to his innocence in the face of criminal allegations. His integrity, proven reliability as a witness, and record of lawful behavior make him an admirable and credible defendant. The claims against him stand in stark contrast to a life lived in full view of the public, where Mr. Trump has consistently demonstrated honesty, lawfulness, and a deep commitment to the American people and the principles of justice. As a witness in his own defense, Mr. Trump’s forthrightness and credibility shine through, making him not only reliable but also fully deserving of the presumption of innocence."
            }
        ]
    }
];
