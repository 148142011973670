import React, { useState, useEffect, useRef } from 'react';
import './Motions.css';
import Button from '@Shared/Button/Button';
import MotionOptionsSelect from './MotionOptionsSelect';
import downloadFile from '@icons/download-file.svg';
import GenerateMotionModal from './GenerateMotionModal/GenerateMotionModal';
import GenerateMotionProvider from './GenerateMotionProvider';
import GenerateMotionButton from './GenerateMotionButton';
import MotionTypeButtons from './MotionTypeButtons';
import LoaderSpinner from '@Shared/LoaderSpinner/LoaderSpinner';
import { useParams } from 'react-router-dom';
import { getAppId } from '@lib/utils/general';
import { useSelector } from 'react-redux';
import { fetchMotion } from '@lib/utils/caseHelpers';
import { cleanupLocalStorage, updateMotionsUI } from '@lib/utils/caseHelpers';
import { getMotionByCaseAndUser } from '@lib/apis/resources/motion';
import { groupedMotionOptions, extractFilename } from '@lib/utils/motionDictionary';
import { getFileUrl } from '@lib/utils/awsSdkHelper';

const Motions = ({ setIsRecommendationsFetched }) => {
  const [motionTypes, setMotionTypes] = useState([]);
  const loadingRef = useRef(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [loadingMessage, setLoadingMessage] = useState('Loading');
  const [recentMotions, setRecentMotions] = useState([]);
  const [motionLoading, setMotionLoading] = useState(false);


  const isMounted = useRef(true);
  const user = useSelector((state) => state.user);
  const userId = user?.cognito_user_id;
  const caseId = id;
  const appId = getAppId();

  useEffect(() => {
    const savedMotionsKey = `motionTypes_${caseId}`;
    const isFetchedKey = `motionTypesFetched_${caseId}`;
    const savedMotions = JSON.parse(localStorage.getItem(savedMotionsKey));
    const isFetched = localStorage.getItem(isFetchedKey);

    cleanupLocalStorage(caseId);

    if (savedMotions && savedMotions?.length > 0) {
      setMotionTypes(savedMotions);
      setLoading(false);
    } else if (!isFetched) {
      handleRefresh();
    }
  }, [userId, caseId, appId]);

  const isComponentMounted = () => {
    return isMounted.current;
  };

  const handleRefresh = async () => {
    if (loadingRef.current) return;

    try {
      loadingRef.current = true;
      setLoading(true);

      const motions = await fetchMotion(userId, caseId, appId, loadingMessage, setLoadingMessage, isComponentMounted);

      if (motions?.length > 0) {
        const updatedMotions = updateMotionsUI(motions, caseId);
        setMotionTypes(updatedMotions);
        setIsRecommendationsFetched(true);
      }
    } catch (error) {
      setIsRecommendationsFetched(false);
    } finally {
      setLoading(false);
      loadingRef.current = false;
    }
  };

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  const fetchRecentlyUsedMotions = async () => {
    setMotionLoading(true);
    try {
      const response = await getMotionByCaseAndUser(caseId, userId);
        if(response?.success === true) {
          setRecentMotions(response?.data); 
        }
    } catch (error) {
      console.log("Error fetching recently used motions:", error)
    } finally {
      setMotionLoading(false);
    }
  } 

  useEffect(() => {
    fetchRecentlyUsedMotions();
  },  [caseId, userId]);

  const filteredMotions = recentMotions?.sort((a, b) => b.timestamp - a.timestamp)?.slice(0, Math.min(recentMotions?.length || 0, 3));

  const matchedMotions = groupedMotionOptions?.flatMap((group) =>
    group?.options
      .filter((option) =>
        filteredMotions?.some((motion) => motion?.fileName?.includes(option?.value))
      )
      .map((option) => ({
        name: option?.name,
        url: filteredMotions.find((motion) => motion?.fileName?.includes(option?.value))?.S3Url,
      }))
  );

  const handleDownloadMotionFile = async (url) => {
    if (url) {
      const fileKey = extractFilename(url);
      const presignedUrl = await getFileUrl(fileKey, userId, caseId);
        if (presignedUrl) {
          window.open(presignedUrl);
        } else {
          console.log("Failed to generate pre-signed URL.");
        }
    } else {
      return;
    }
  };

  return (
    <GenerateMotionProvider>
      <div className="motion-container">
        <h3 className="cases-motion-title">Motions</h3>
        <div className="motion-recommendations-container">
          <div className="motion-recommendations-title-container">
            <h4>Fedcrim.ai Recommends</h4>
            {!loading && (
              <Button className="button--primary retry-atticus-recommends" onClick={handleRefresh}>
                Refresh
              </Button>
            )}
          </div>
          {loading ? (
            <>
              <LoaderSpinner color="#2dc008" interval={10000} messages={['Loading', 'Waiting for response.', 'Checking database.', 'Processing request.']} />
            </>
          ) : (
            <>
              <MotionTypeButtons motionTypes={motionTypes} />
              <MotionOptionsSelect />
              <GenerateMotionButton />
            </>
          )}
        </div>
        <div className="recent-motions-container">
          <h4>Recently Generated Motions</h4>
          {motionLoading ? (
              <LoaderSpinner hideText={true} color='var(--color-dark-green)' />
            ) : (
              Array.isArray(matchedMotions) && matchedMotions?.length > 0 ? (
                matchedMotions?.map((motion, index) => (
                  <div className="recent-motion" key={index}>
                    <a onClick={() => handleDownloadMotionFile(motion.url)} className="recent-motion-download-text">
                      <img src={downloadFile} alt="" className='download-file-icon' />
                      {motion.name ? motion.name : '' }
                    </a>
                  </div>
                ))
              ) : (
                <p>No recently used motion available.</p>
              )
            )
          }
        </div>
        <GenerateMotionModal />
      </div>
    </GenerateMotionProvider>
  );
};

export default Motions;
