import React from 'react';
import NavBar from '@Shared/NavBar/NavBar';
import './Firms.css';
import PricingCards from '../Shared/PricingCards/PricingCards';

const plans = [
  {
    title: "Private Attorneys",
    price: "$499",
    description: "Optimize your legal practice with essential AI tools.",
    features: [
      "Access to Web Dashboard",
      "Brief Drafting Assistance",
      "Case Motion Recommendations",
      "Docket Fetcher",
      "Hallucination Detection",
      "Case Citation Assistance",
      "Chatbot Support",
    ],
    buttonText: "Get Started",
    link: '/private-attorney',
  },
  {
    title: "Small Firms",
    price: "$999",
    description: "Elevate your firm's capabilities with advanced AI features.",
    features: [
      "All Standard Plan Features",
      "Enhanced Chatbot with Natural Language Processing",
      "Advanced Discovery Analysis Tools",
      "Priority Support for Query Resolution",
      "Customizable Brief Templates",
    ],
    buttonText: "Get Started",
    isHighlighted: true,
    link: '/small-firm',
  },
  {
    title: "Big Firms",
    price: "Contact Sales for Pricing",
    description: "Get tailored AI solutions and premium support for large firms.",
    features: [
      "All Premium Plan Features",
      "Dedicated Account Manager",
      "Personalized Training Sessions",
      "Tailored AI Models for Your Practice",
      "Early Access to New Features",
      "Unlimited Case Citations",
    ],
    buttonText: "Contact Sales",
    link: '/big-firm',
  },
];


const Pricing = () => {
  
  return (
    <>
      <NavBar />
      <div className="pricing-plan-main-container">
        <div className="pricing-plan-header-section">
          <div className="pricing-plan-title-section">
            <span>Pricing plans</span>
            <h1 className="pricing-plan-title">Unlock the Power of AI for Legal Success with JuristAI</h1>
          </div>
          <p className="pricing-card-header-text">
            Embrace the Future of Legal Practice with JuristAI's Revolutionary AI-powered Solutions. Choose the Perfect
            Plan to Elevate Your Legal Practice.
          </p>
        </div>

        <div className="pricing-cards-container">
          {plans?.map((plan, index) => (
            <PricingCards key={index} {...plan} />
          ))}
        </div>
      </div>
    </>
  );
};

export default Pricing;
