import React from 'react';
import './RoleSelection.css';

const RoleSelection = ({ roles, selectedRole, onRoleChange, isAnalyze }) => {
  return (
    <div className="chat-role-selection-container">
      {roles?.map((role) => (
        <div
          key={role.id}
          className={`chat-role-item-container ${selectedRole?.id === role.id ? 'selected' : ''} ${selectedRole && selectedRole?.id !== role.id ? 'disabled' : ''} ${
            isAnalyze ? 'analyze' : ''
          }`}
          onClick={() => onRoleChange(role)}
        >
          <div className="chat-role-item-heading-container">
            <input type="checkbox" checked={selectedRole?.id === role.id} disabled={selectedRole && selectedRole?.id !== role.id} readOnly />
            <h3 className="chat-role-item-title">{role.title}</h3>
          </div>
          {role?.description && (
            <div className="chat-role-content">
              <p className="chat-role-description">{role.description}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default RoleSelection;
