import React, { useState, useRef, useEffect, useContext } from 'react';
import './Profile.css';
import Header from '../../Shared/Header/Header';
import RoleSelection from '../Shared/RoleSelection/RoleSelection';
import MessageCard from '../Shared/MessageCard/MessageCard';
import { profileGeneratedData, profileRolesData } from './profileData';
import RoleDetailsCard from '../Shared/RoleDetailsCard/RoleDetailsCard';
import { sendMessage } from '@lib/apis/chatbot';
import { useSelector } from 'react-redux';
import { chatbotPromptsMap, formatChatGPTResponse, getTagFromLocation } from '@lib/utils/chatbot';
import ChatContext from '../../ChatContext';
import Message from '../../Research/Message/Message'

const Profile = () => {
  const { addResponseMessage, currentCase, conversationId, parentMessageId, messages, currentThread } = useContext(ChatContext);
  const [selectedRole, setSelectedRole] = useState(null);
  const [name, setName] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const selectedRoleMessageRef = useRef(null);
  const generatedDataRef = useRef(null);
  const auth = useSelector(state => state.auth);
  const user = useSelector(state => state.user);
  const [responseText, setResponseText] = useState(null);

  const handleRoleChange = (newRole) => {
    setSelectedRole((prev) => (prev?.id === newRole?.id ? null : newRole));
    setName('');
    setIsGenerating(false);
  };

  const handleContinue = async (name) => {
    setName(name);
    setIsGenerating(true);
    let text = "Profile Generation: ";
    text += chatbotPromptsMap.profileFlow;
    text += `\n\n${selectedRole.flow}${name}`;

    try {
      const params = {
        input: text,
        threadId: currentThread?.threadId || null,
        conversationId: conversationId || null,
        parentMessageId: parentMessageId || null,
        userId: user.cognito_user_id,
        caseId: currentCase?.caseId,
        tag: getTagFromLocation(location),
      }
      const response = await sendMessage(params, auth.chatToken);
      const formattedResponse = formatChatGPTResponse(response?.data?.text);
      setResponseText(formattedResponse);
    } catch (error) {
      console.error('Error sending message:', error);
    }

    setTimeout(() => {
      setIsGenerating(false);
    }, 1000);
  };

  const handleRetry = () => {
    console.log('retry button clicked');
  };

  const selectedRoleData = profileRolesData.find((role) => role?.id === selectedRole?.id);

  useEffect(() => {
    if (selectedRoleData) {
      selectedRoleMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [selectedRoleData]);

  useEffect(() => {
    if (name && !isGenerating) {
      generatedDataRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [name, isGenerating]);

  return (
    <div className="chat-profile-container">
      <Header />
      <div className="chat-profile-content">
        <div className="chat-profile-time">Today, 09:00 AM</div>
        <MessageCard text="Welcome to the Develop a Profile section! I can help you create a detailed profile of a party involved in your case, such as a defendant, witness, or expert. Who would you like to develop a profile for today?" />
        <RoleSelection roles={profileRolesData} selectedRole={selectedRole} onRoleChange={handleRoleChange} />

        {selectedRoleData && (
          <>
            <div ref={selectedRoleMessageRef}>
              <MessageCard
                text={`You have selected ${selectedRoleData.title}. Please provide the name of the ${selectedRoleData.title} for whom you want to generate a profile.`}
              />
            </div>
            <RoleDetailsCard title={selectedRoleData.title} onContinue={handleContinue} />

            {name && isGenerating && (
              <MessageCard text={`Profile generation in progress for ${name}. Please hold on while I gather the relevant details...`} />)

            }

            {name && !isGenerating && (
              <div ref={generatedDataRef}>
                <MessageCard text={responseText ?? responseText} handleRetry={handleRetry} />
              </div>
            )}
          </>
        )}
        {messages.map((message) => (
          <Message
            key={message.id}
            text={message?.text ? message.text : message?.content?.[0]?.text?.value ? message.content[0].text.value : ""}
            userIsAuthor={message.sender === "User" || message.userIsAuthor}
          />
          ))}
      </div>
    </div>
  );
};

export default Profile;
