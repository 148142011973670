import React, { useEffect, useState } from 'react';
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as DropdownArrowIcon } from '@icons/arrow-dropdown.svg';
import Dropdown from 'react-bootstrap/Dropdown';
import NavBar from '../Shared/NavBar/NavBar';
import './Layout.css';
import useRedirectIfNeeded from '@hooks/useRedirectIfNeeded';
import Motions from '../Cases/CasesShow/CaseOverview/Motions/Motions';
import { useSelector } from 'react-redux';
import { error } from '@lib/utils/toast';
import { getCase } from '@lib/apis/resources/case';
import AccountDropdown from '@Shared/AccountDropdown/AccountDropdown';

// import Motions from '../Cases/CasesIndex/CaseOverview/Motions/Motion';

const Layout = () => {
  const isAuthenticated = useSelector((state) => state?.auth?.isAuthenticated);
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [selectedCase, setSelectedCase] = useState(null);
  const [isRecommendationsFetched, setIsRecommendationsFetched] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const { id } = useParams();

  const dropdownOptions = [
    {
      text: 'Federal Criminal',
      link: '#',
    },
    {
      text: 'Federal Civil',
      link: '#',
    },
    {
      text: 'Federal Bankruptcy',
      link: '#',
    },
  ];

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
      error('Please log in.');
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    const fetchCase = async () => {
      if (id) {
        const response = await getCase(id, 'token');
        const caseData = response?.data?.case ?? [];
        setSelectedCase(caseData);
      } else {
        setSelectedCase(null);
      }
    };
    fetchCase();
  }, [id, navigate]);

  // useRedirectIfNeeded(selectedCase, setSelectedCase);
  const isShowMotions = pathname.includes('/overview') || pathname.includes('/timeline');

  useEffect(() => {
    const caseSpecificFetchedKey = `motionTypesFetched_${id}`;
    const fetchedStatus = localStorage.getItem(caseSpecificFetchedKey);
    setIsRecommendationsFetched(fetchedStatus ? true : false);
  }, [id]);

  return (
    <div className="dashboard-flex-wrapper">
      <NavBar selectedCase={selectedCase} />
      <div className="dashboard-main">
        <div className="dashboard-header" id='atticus-dashboard-header'>
          {selectedCase ? (
            <div className="case-content-container">
              <p className="case-name">{selectedCase?.caseName}</p>{' '}
              <p className="criminal-no">{`${selectedCase?.docketNumber ? ` Criminal No: ${selectedCase?.docketNumber}` : ''}`}</p>
            </div>
          ) : (
            <>
              <p className="dashboard-header-main-text">Welcome to Fedcrim.ai!</p>
              <p className="dashboard-header-sub-text">Selected practice area:</p>
              <Dropdown className="dashboard-header-dropdown">
                <Dropdown.Toggle variant="" id="header-dropdown">
                  <p className="header-dropdown-toggle-text">Federal Criminal</p>
                  <DropdownArrowIcon />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {dropdownOptions.map((option, index) => (
                    <Dropdown.Item key={index}>
                      <Link to={option.link}>{option.text}</Link>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <AccountDropdown user={user} />
            </>
          )}
        </div>
        <main className="dashboard-content">
          <Outlet
            context={{
              setSelectedCase,
              isRecommendationsFetched,
              selectedCase,
            }}
          />
        </main>
      </div>
      {isShowMotions && <Motions setIsRecommendationsFetched={setIsRecommendationsFetched} />}
    </div>
  );
};

export default Layout;
