import React, { useState, useEffect } from 'react';
import Button from '@Shared/Button/Button';
import { useLocation, useParams } from 'react-router-dom';
import checkMarkIcon from '@assets/images/atticus/icons/check-line-green.svg';
import arrowRightBlackIcon from '@assets/images/atticus/icons/arrow-right-gray.svg';
import loopIcon from '@assets/images/atticus/icons/loading-icon.svg';
import GenerateMotionModalPage from '../GenerateMotionModalPage';
import { generatingMotionSteps } from './generatingMotionSteps';

const GenerateMotionLoader = ({ pageIndex, step = 0 }) => {
  const params = useParams();
  const caseId = params.id;

  const [steps, setSteps] = useState(generatingMotionSteps);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    setSteps(
      generatingMotionSteps.map((step) => ({
        ...step,
        isProcessing: 'pending',
      }))
    );
    setCurrentIndex(0);
  }, [step]);

  useEffect(() => {
    let timeoutId;

    if (currentIndex < steps.length) {
      setSteps((prevSteps) => {
        const updatedSteps = [...prevSteps];
        updatedSteps[currentIndex].isProcessing = 'processing';
        return updatedSteps;
      });

      if (currentIndex < steps?.length - 1) {
        timeoutId = setTimeout(() => {
          setSteps((prevSteps) => {
            const updatedSteps = [...prevSteps];
            updatedSteps[currentIndex].isProcessing = 'completed';
            return updatedSteps;
          });
          setCurrentIndex(currentIndex + 1);
        }, 20000);
      }
    }
  }, [currentIndex, steps.length]);

  return (
    <GenerateMotionModalPage title="Generating Your Motion" pageIndex={pageIndex} className='p-4'>
      {steps.map((step, index) => {
        return (
          <div className="generating-motion-step" key={index}>
            {step?.isProcessing === 'pending' && <img src={arrowRightBlackIcon} alt="checkmark" width={20} />}
            {step?.isProcessing === 'processing' && <img className="generating-motion-step-processing" src={loopIcon} alt="checkmark" width={20} />}
            {step?.isProcessing === 'completed' && <img src={checkMarkIcon} alt="checkmark" width={20} />}
            <p>{step.text}</p>
          </div>
        );
      })}
      <div className="generating-motion-browse-main">
        <Button className="button--primary">
          <a href={`/dashboard/cases/${caseId}/overview`} target="_blank">
            Browse
          </a>
        </Button>
      </div>
    </GenerateMotionModalPage>
  );
};

export default GenerateMotionLoader;
