import React from 'react';
import NavBar from '@Shared/NavBar/NavBar';
import PricingPlans from '../Shared/PricingPlans/PricingPlans';

const smallFirmsPoints = [
  'All Standard Plan Features',
  'Enhanced Chatbot with Natural Language Processing',
  'Advanced Discovery Analysis Tools',
  'Priority Support for Query Resolution',
  'Customizable Brief Templates',
];

const SmallFirm = () => {
  return (
    <>
      <NavBar />
      <PricingPlans
        title="Small Firms"
        description="Elevate your firm's capabilities with advanced AI features."
        price="999"
        buttonText="Book a Demo"
        features={smallFirmsPoints}
        showIncludedHeading={false}
        link='/book-a-demo'
      />
    </>
  );
};

export default SmallFirm;
