import React, { useEffect, useState } from 'react';
import CaseSearchFilter from '@Shared/CaseSearchFilter/CaseSearchFilter';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as Eye } from '@icons/eye-black.svg';
import { ReactComponent as Trashcan } from '@icons/trashcan.svg';
import './MyCasesTable.css';
import { formatMMDDYYYY } from '@lib/utils/dateFormatHelpers';
import ConfirmationModal from '@Shared/ConfirmationModal/ConfirmationModal';
import { deleteCases, removeUserFromCase } from '@lib/apis/resources/case';
import Loader from '@Shared/Loader/Loader';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const MyCasesTable = ({ cases, onSelectCase, isLoading, onReload }) => {
  const { cognito_user_id: userId } = useSelector((state) => state.user);
   const [showConfirmationModal, setShowConfirmationModal] = useState(false);
   const [selectedCaseId, setSelectedCaseId] = useState(null);
   const [isDeleting, setIsDeleting] = useState(false);
   const [filter, setFilter] = useState('');
   const location = useLocation();

    const handleConfirmationDelete = (caseId) => {
      setSelectedCaseId(caseId);
      setShowConfirmationModal(true);
    };
  
    const handleCloseModal = () => {
      setShowConfirmationModal(false);
    };
  
    const handleConfirmDelete = async () => {
      setIsDeleting(true);
      try {
        const removeUserResponse = await removeUserFromCase(selectedCaseId, userId);

        if (removeUserResponse?.success || removeUserResponse === null) {
          setShowConfirmationModal(false);
          toast.success('Case Deleted Successfully')
          onReload();
        } 

      } catch (error) {
        console.error('Error confirming delete:', error);
      } finally {
        setShowConfirmationModal(false);
        setIsDeleting(false);
      }
    };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const filterParam = queryParams?.get('filter');
      if (filterParam) {
        setFilter(filterParam);
      }
  }, [location.search]);

  const filteredCases = (Array.isArray(cases) ? cases : [])?.filter((caseItem) => {
    if(!caseItem) return false;

    if (filter === 'open') {
      return !caseItem?.dateTerminated;
    } else if (filter === 'closed') {
      return caseItem?.dateTerminated;
    }
    return true;
  });

  if (isLoading) {
    return <Loader />; 
  }

  return (
    <>
    <div className="cases-index-table">
      <div className="cases-index-table-header">
        <h3>Cases</h3>
        <div className="cases-index-table-header-buttons">
          <CaseSearchFilter onFilterChange={setFilter} />
        </div>
      </div>
      <ul>
        <li className="cases-index-table-heading">
          <p>Case Name</p>
          <p>Full Number</p>
          <p>Team Name</p>
          <p>Latest Entry</p>
          <p>Status</p>
          <p>Action</p>
        </li>
        {filteredCases && filteredCases?.length > 0 ? (
          filteredCases?.map((caseItem, index) => (
            <li key={index} className="cases-index-table-row">
              <p>
                <Link to={`/dashboard/cases/${caseItem.caseId}/overview`}>
                  {caseItem?.caseName}
                </Link>
              </p>
              <p>{caseItem?.docketNumber}</p>
              <p>My Team</p>
              <p>{formatMMDDYYYY(caseItem?.dateFiled)}</p>
              <div
                className={`cases-index-table-row-status ${
                  caseItem?.dateTerminated == null || "" ? 'open' : 'closed'
                }`}
              >
                <p>{caseItem?.dateTerminated ? "Closed" : "Open"}</p>
              </div>
              <div className="cases-list-actions-container">
                <Link
                  className="hover-cursor cases-action-buttons"
                  onClick={() => onSelectCase(caseItem)}
                  to={`/dashboard/cases/${caseItem.caseId}/overview`}
                  title={`View case ${caseItem?.caseName}`}
                >
                  <Eye className="cases-action-buttons"/>
                </Link>
                <Trashcan
                  className="hover-cursor cases-action-buttons"
                  title={`Delete case ${caseItem?.caseName}`}
                  onClick={() => handleConfirmationDelete(caseItem?.caseId)}
                />
              </div>
            </li>
          ))
        ) : (
          <p className="empty-message text-center mt-3">
            No relevant cases records were found.
          </p>
        )}
      </ul>
    </div>
      <ConfirmationModal
        isOpen={showConfirmationModal}
        onClose={handleCloseModal}
        heading="Confirm Deletion"
        cancelText="Cancel"
        successText="Delete"
        onSuccess={handleConfirmDelete}
        isDeleting={isDeleting}
      >
        <p>
          Are you sure you want to delete this case? This action cannot
          be undone.
        </p>
      </ConfirmationModal>
   </>
  );
};

export default MyCasesTable;
