import React, { useContext } from 'react';
import ChatContext from '../../ChatContext';
import './FeatureCard.css';
import { Link, useParams } from 'react-router-dom';

const FeatureCard = ({ icon, title, description, path, promptFlow }) => {
  const { setWorkflow, currentCase } = useContext(ChatContext);
  const { caseId } = useParams();

  return (
    <Link to={`/chat/case/${caseId}/${path}`} state={promptFlow}>
      <div className="chat-feature-card cursor-pointer">
        <div className="chat-feature-heading">
          {' '}
          <img src={icon} alt={`${title} icon`} className="chat-feature-icon" /> <h3>{title}</h3>
        </div>
        <div className="chat-feature-content">
          <p>{description}</p>
        </div>
      </div>
    </Link>
  );
};

export default FeatureCard;
