import React from 'react';
import './MeetAtticusSection.css';
import demoImage from './../../../../../assets/images/atticus/meetAtticus/demo-image.png';
import DemoSlider from '@Shared/Slider/DemoSlider.jsx/DemoSlider';
import { meetAtticusData } from './meetAtticusData';

const MeetAtticusSection = () => {
  return (
    <section className="meet-atticus-section-container">
      <div className="meet-atticus-section">
        <div className="meet-atticus-content">
          <h2 className="meet-atticus-heading">Meet Fedcrim.ai: Your First AI Defense Attorney</h2>
          <p className="meet-atticus-description">
            Explore the Future of Legal Practice with Fedcrim.ai. From intelligent legal guidance to seamless case management, Fedcrim.ai empowers you with
            advanced AI technology to navigate the complexities of the legal world with confidence and efficiency.
          </p>
        </div>
        <div>
          <img src={demoImage} alt="Fedcrim.ai Demo" className="meet-atticus-demo-image" />
        </div>
        <div className="meet-atticus-content">
          <h2 className="meet-atticus-sub-heading">What can Fedcrim.ai do?</h2>
        </div>
        <div className="meet-atticus-slider-container">
          <DemoSlider data={meetAtticusData} />
        </div>
      </div>
    </section>
  );
};

export default MeetAtticusSection;
