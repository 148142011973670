import React from 'react';
import { Link } from 'react-router-dom';
import './HeroSection.css';
import ArrowLeft from '@icons/arrow-left-green.png';
import EmailSignUp from '@Shared/NewsLetter/EmailSignUp';
import YoutubePlaceholder from '@Shared/Youtube/YoutubePlaceholder';

const youtubeVideoID = '28jIX6vxxqE';

const HeroSection = () => {
  return (
    <section className="hero-section-container">
      <div className="hero-section">
        <div className="hero-content">
          <h1>Fedcrim.ai is the future of criminal defense.</h1>
          <p>It's a one-person Al law firm to help attorneys while keeping them in the loop, so they can focus on what they excel at.</p>
          <div className="hero-tools ">
            <Link to="#" className="button-disabled">
              <img src={ArrowLeft} alt="arrow_left" /> <span>Web Dashboard</span>
            </Link>
            <Link to={'#'} className="button-disabled">
              <img src={ArrowLeft} alt="arrow_left" /> Full Motion/Brief Drafting
            </Link>
            <Link to={'#'} className="button-disabled">
              <img src={ArrowLeft} alt="arrow_left" /> Research Citations
            </Link>
            <Link to={'#'} className="button-disabled">
              <img src={ArrowLeft} alt="arrow_left" /> Response Motion Recommendations
            </Link>
            <Link to={'#'} className="button-disabled">
              <img src={ArrowLeft} alt="arrow_left" /> Document Management
            </Link>
            <Link to={'#'} className="button-disabled">
              <img src={ArrowLeft} alt="arrow_left" /> Chatbot
            </Link>
            <Link to={'#'} className="button-disabled">
              <img src={ArrowLeft} alt="arrow_left" /> Discovery Analysis
            </Link>
            <Link to={'#'} className="button-disabled">
              <img src={ArrowLeft} alt="arrow_left" /> Hallucination/Error Detection
            </Link>
          </div>
          <div className="hero-newsletter">
            <EmailSignUp />
          </div>
          <div className="hero-sub-description">
            The first version of Fedcrim.ai is available for demo & free trial. Please reach out to us to book a time & get started!
          </div>
        </div>
        <div className="hero-section-youtube-container">
          <YoutubePlaceholder videoId={youtubeVideoID} />
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
