import React, { useEffect, useState } from 'react';
import './ManageOrganization.css';
import searchIcon from '@icons/search-gray-icon.svg';
import Dropdown from '@Shared/Dropdown/Dropdown';
import Button from '@Shared/Button/Button';
import DataTable from '@Shared/DataTable/DataTable';
import viewIcon from '@icons/eye-black.svg';
import deleteIcon from '@icons/delete-green-icon.svg';
import { manageOrganizationData } from './manageOrganizationData';
import { Link } from 'react-router-dom';
import Drawer from '@Shared/Drawer/Drawer';
import AddTeamMember from '../AddTeamMember/AddTeamMember';
import { useSelector } from 'react-redux';
import Loader from '@Shared/Loader/Loader';
import { getUser, getOrganizationDetails, orgUsersWithAppAccess } from '@lib/apis/resources/user';
import ROLES from '@lib/data/userRoles';

const PRACTICE_AREAS = ['All Practice Areas', 'Area 1', 'Area 2', 'Area 3'];
const FUNCTIONS = ['All Functions', 'Function 1', 'Function 2', 'Function 3'];

const ManageOrganization = () => {
  const user = useSelector((state) => state.user);
  const [selectedPracticeArea, setSelectedPracticeArea] = useState(
    PRACTICE_AREAS[0]
  );
  const [selectedRole, setSelectedRole] = useState(ROLES[0]);
  const [selectedFunction, setSelectedFunction] = useState(FUNCTIONS[0]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [organizationData, setOrganizationData]= useState([]);
  const [loading, setLoading]= useState(true);

  const handlePracticeAreaSelect = (area) => {
    setSelectedPracticeArea(area);
  };

  const handleRoleSelect = (role) => {
    setSelectedRole(role);
  };

  const handleFunctionSelect = (func) => {
    setSelectedFunction(func);
  };

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const headings = [
    'Team Member',
    'Application Role',
    'Position',
    'Practice Areas',
    'Assigned Case(s)',
    'Action',
  ];

  const fetchData = async () => {
    try {
      if(!user) {
        console.log('go to login to set user');
        return;
      }

      const getUserResponse = await getUser(user?.email);
      const organizationId = getUserResponse?.data?.user?.organizationId;
              
      if(!organizationId) {
        return;
      }

      const fetchOrganizationDetail = async () => {
        try {
          const response =  await getOrganizationDetails(organizationId);
          const getOrganization = response?.data?.orgUsers || [];
          return getOrganization;
        } catch (err) {
          console.error('Error organization Detail:', err);
          return [];
        }
      };

      const fetchOrgUserWithAppAccess = async () => {
        try {
          const response =  await orgUsersWithAppAccess(organizationId);
          const getOrgUsers = response?.data || [];
          return getOrgUsers;
        } catch (err) {
          console.error('Error OrgUserWithAppAccess API:', err);
          return [];
        }
      };

      const [organizationDetail, orgUsersDetail] = await Promise.all([
        fetchOrganizationDetail(),
        fetchOrgUserWithAppAccess(),
      ]);

      const combinedData = organizationDetail.map((member) => {
        const matchedUser = orgUsersDetail?.find((orgUser) => orgUser.userId === member.userId);
        return {
          ...member,
          ...matchedUser,
        }
      })

      setOrganizationData(combinedData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [user])

  if(loading) {
    return <Loader/>
  }

  const renderRow = (member) => (
    <>
      <td className="dashboard-organization-member-info ">
        {member?.avatar && (
          <img
            src={member.avatar}
            alt={`${member?.firstName} ${member?.lastName} avatar`}
            className="member-info-avatar"
          />
        )}
        <span>{member?.firstName + " " + member?.lastName}</span>
      </td>
      <td>{member?.accessLevel ? member.accessLevel : "-" }</td>
      <td>{member?.position ? member?.position : "-"}</td>
      <td>{member?.practiceAreas ? member?.practiceAreas : "-"}</td>
      <td>
        {member?.assignedCases?.length > 0 ? member?.assignedCases?.length : 0} (
        <Link
          href={member.viewLink}
          className="dashboard-organization-view-link"
        >
          View
        </Link>
        )
      </td>
      <td className="table-action-icons">
        <img src={viewIcon} alt="View" className="action-icon" />
        <img src={deleteIcon} alt="Delete" className="action-icon" />
      </td>
    </>
  );

  return (
    <div className="dashboard-organization-container">
      <div className="organization-dashboard-sub-header-container">
        <h1 className="dashboard-organization-heading">Manage Organization</h1>
        <div>
          <div className="search-container">
            <div className="search-input-container">
              <img src={searchIcon} alt="Search Icon" className="search-icon" />
              <input
                type="text"
                placeholder="Search member..."
                className="search-input"
              />
            </div>
            <Dropdown
              options={PRACTICE_AREAS}
              selectedOption={selectedPracticeArea}
              onOptionSelect={handlePracticeAreaSelect}
              placeholder="Select Practice Area"
              isFilter
            />
            <Dropdown
              options={ROLES}
              selectedOption={selectedRole}
              onOptionSelect={handleRoleSelect}
              placeholder="Select Role"
              isFilter
            />
            <Dropdown
              options={FUNCTIONS}
              selectedOption={selectedFunction}
              onOptionSelect={handleFunctionSelect}
              placeholder="Select Function"
              isFilter
            />
          </div>
        </div>
      </div>
      <div className="dashboard-organization-table-container rounded-border white-background">
        <div className="dashboard-organization-table-header-container">
          <h2>Members</h2>
          <Button className="button--primary" onClick={toggleDrawer}>
            + Add Members{' '}
          </Button>
        </div>
        {organizationData?.length === 0 ? (
          <p className='not-found-organization-text'>No organization members found.</p>
        ) : (
          <DataTable
            headings={headings}
            data={organizationData}
            renderRow={renderRow}
            isPagination={true}
          />
        )}
      </div>
      <Drawer
        title={'Invite Member'}
        isOpen={openDrawer}
        onClose={toggleDrawer}
      >
        {' '}
        <AddTeamMember />{' '}
      </Drawer>
    </div>
  );
};

export default ManageOrganization;
