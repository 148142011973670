import React, { useState } from "react";
import Button from "@Shared/Button/Button";
import TextField from "@Shared/TextField/TextField";
import AtticusAiLogo from "@assets/images/atticus/logo/atticus-full-logo.svg";
import "./ForgotPassword.css";
import { sendPasswordResetRequest } from "@lib/apis/password-reset";
import LoaderSpinner from "@Shared/LoaderSpinner/LoaderSpinner";
const ForgotPassword = () => {
  const [formData, setFormData] = useState({ email: "" });
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [showButton, setshowButton] = useState(true);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const handleClick  = async (event) => {
    event.preventDefault();
    if (!formData.email) {
      setResponseMessage("Please provide an email address.");
      return;
    }
    setLoading(true);
    const success = await sendPasswordResetRequest(formData.email);
    if (success) {
      setResponseMessage("Password reset email sent.");
    } else {
      setResponseMessage("Error sending password reset email.");
    }
    setLoading(false);
    setshowButton(false);
  }
  return (
    <main className="forgot-password-page">
    <div className='gradient-color-background-right'></div>

    <section className="fp-right">
      <div className="fp-form-container">
        <form>
          <h1>Reset Password</h1>
          <p>Provide your email to reset your password</p>
          <TextField
            placeholder="Email address"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
          { showButton && (
          <Button type="submit" onClick={handleClick} className="button--primary fp-page-button" disabled={loading}>
            {loading
              ? <LoaderSpinner messages={''} hideText={false} color='var(--color-black)' width={20} /> 
              : 'Reset Password'
            }
          </Button>
          )}
          <p className="fp-response-message">{responseMessage}</p>
        </form>
      </div>
    </section>
  </main>
  );
}

export default ForgotPassword;