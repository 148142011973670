import React, { useState } from 'react';
import NavBar from '@Shared/NavBar/NavBar';
import './BookADemo.css';
import TextField from '@Shared/TextField/TextField';
import Dropdown from '@Shared/Dropdown/Dropdown';
import Button from '@Shared/Button/Button';

const ORGANIZATION_OPTIONS = ['Please Select', 'Civil', 'Federal'];
const ROLE_OPTIONS = ['Please Select', 'Admin', 'Member'];

const BookADemo = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    businessEmail: '',
    zipCode: '',
    phoneNumber: '',
    organization: ORGANIZATION_OPTIONS[0],
    role: ROLE_OPTIONS[0],
  });

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleOrganizationSelect = (org) => {
    setFormData({ ...formData, organization: org });
  };

  const handleRoleSelect = (role) => {
    setFormData({ ...formData, role });
  };

  const handleDemoFormSubmit = (e) => {
    e.preventDefault();
    window.open('https://calendly.com/ali-juristai/demo');
    setFormData({
      firstName: '',
      lastName: '',
      businessEmail: '',
      zipCode: '',
      phoneNumber: '',
      organization: ORGANIZATION_OPTIONS[0],
      role: ROLE_OPTIONS[0],
    });
  };

  return (
    <section className="contact-sales-section-container">
      <NavBar />

      <div className="book-demo-main--container">
        <div className="book-demo-content-section">
          <div className="book-demo--content">
            <span>Book a Demo</span>
            <h1 className="book-demo--title">Book a Demo with a JuristAI representative</h1>
          </div>
          <p>
            Unlock the power of AI to revolutionize your legal practice. Begin your journey with JuristAI's free trial
            and experience the future of law firsthand
          </p>
        </div>

        <div className="book-demo--form-section">
          <form onSubmit={handleDemoFormSubmit} className="book-demo--form">
            <div className="book-demo--input-fields">
              <TextField
                type="text"
                name="firstName"
                placeholder="First Name"
                className="demo--input-field"
                value={formData.firstName}
                onChange={handleInputChange}
              />
              <TextField
                type="text"
                name="lastName"
                placeholder="Last Name"
                className="demo--input-field"
                value={formData.lastName}
                onChange={handleInputChange}
              />
            </div>

            <div className="book-demo--input-fields">
              <TextField
                type="email"
                name="businessEmail"
                placeholder="Business Email"
                className="demo--input-field"
                value={formData.businessEmail}
                onChange={handleInputChange}
              />
            </div>

            <div className="book-demo--dropdown">
              <label className="book-demo--dropdown--label">What best describes your organization?</label>
              <Dropdown
                options={ORGANIZATION_OPTIONS}
                selectedOption={formData.organization}
                onOptionSelect={handleOrganizationSelect}
                placeholder="Please Select"
                className="book-demo--dropdown-field"
              />
            </div>

            <div className="book-demo--input-fields">
              <TextField
                type="number"
                name="zipCode"
                placeholder="ZIP Code"
                className="demo--input-field"
                value={formData.zipCode}
                onChange={handleInputChange}
              />
              <TextField
                type="text"
                name="phoneNumber"
                placeholder="Phone Number"
                className="demo--input-field"
                value={formData.phoneNumber}
                onChange={handleInputChange}
              />
            </div>

            <div className="book-demo--dropdown">
              <label className="book-demo--dropdown--label">What best describes your role?</label>
              <Dropdown
                options={ROLE_OPTIONS}
                selectedOption={formData.role}
                onOptionSelect={handleRoleSelect}
                placeholder="Please Select"
                className="book-demo--dropdown-field"
              />
            </div>

            <Button type="submit" className="button--primary book-demo--button">
              Submit
            </Button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default BookADemo;
