import { m } from 'framer-motion';
import { authTypes, userTypes } from '../types';
import * as api from '@lib/apis/auth';
import { useParams } from 'react-router-dom';
import axiosInstance from '@lib/apis/axiosInstance';



export const registerEmail = (email, password) => async (dispatch) => {
  try {
    const response = await api.registerEmail(email, password);
    if (response.success) {
      localStorage.removeItem('access');
      localStorage.removeItem('access_expiration');
      localStorage.removeItem('refresh');
      localStorage.removeItem('refresh_expiration');
      localStorage.removeItem('user');
      localStorage.removeItem('isAuthenticated');
      localStorage.removeItem('chatToken');
      return {
        success: true,
        message: "Success! Please check your email to confirm."
      }
    }
  } catch (error) {
    console.error('Error registering email:', error);
    return {
      success: false,
      message: "Error registering email. Please try again."
    }
  }
}

export const logInEmail = (email, password) => async (dispatch) => {
  try {
    const response = await api.logInEmail(email, password);
    if (response.success) {
      const data = response.data;
      console.log("data in login", data);
      localStorage.setItem('access', data.access);
      localStorage.setItem('access_expiration', data.access_expiration);
      localStorage.setItem('refresh', data.refresh);
      localStorage.setItem('refresh_expiration', data.refresh_expiration);
      localStorage.setItem('user', JSON.stringify(data.user));
      localStorage.setItem('isAuthenticated', true);
      console.log('chatToken in login', data.chat_token);
      console.log("data in login", data);
      localStorage.setItem('chatToken', data.chat_token);
      localStorage.setItem('access_expiration', data.access_expiration);
      localStorage.setItem('refresh_expiration', data.refresh_expiration);
      
      dispatch({
        type: authTypes.LOG_IN, payload: {
          acces: data.access,
          access_expiration: data.access_expiration,
          refresh: data.refresh,
          refresh_expiration: data.refresh_expiration,
          user: data.user,
          isAuthenticated: true,
          chatToken: data.chat_token,          
        }
      });
      return {
        success: true,
        data: data,
      };
    } else {
      return {
        success: false,
        message: response.message,
      };
    }
  } catch (error) {
    return {
      success: false,
    };
  }
}

export const logOut = () => async (dispatch) => {
  try {
    localStorage.removeItem('access');
    localStorage.removeItem('access_expiration');
    localStorage.removeItem('refresh');
    localStorage.removeItem('refresh_expiration');
    localStorage.removeItem('user');
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('chat_token');
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith('motionTypes_') || key.startsWith('motionTypesFetched_') || key.startsWith('caseSummary_')) {
        localStorage.removeItem(key);
      }
    });
    dispatch({ type: authTypes.LOG_OUT, payload: null });
  } catch (error) {
    console.error('Error logging out:', error);
  }
}

export const refreshToken = () => async (dispatch, getState) => {
  const URI = `${process.env.REACT_APP_ATTICUS_API_URL}/auth/token/refresh/`;
  console.log("---Getting refreshing token")

  try {
    const response = await axiosInstance.post(URI, {
      "refresh": getState().auth.refresh,
    });

    console.log('response in refreshToken', response);

    if (response.status !== 200) {
      dispatch({ type: authTypes.REFRESH_TOKEN_FAILURE, payload: { access: null, refresh: null, isAuthenticated: false} });
      dispatch({ type: authTypes.LOG_OUT, payload: null });
    } else {
      dispatch({ type: authTypes.REFRESH_TOKEN_SUCCESS, 
          payload: { access: response.data.access,
                    access_expiration: response.data.access_expiration,
                    isAuthenticated: true
                  }
      });
    }

  } catch (error) {

    console.error('Error refreshing token:', error);
    dispatch({type: authTypes.LOG_OUT, payload: null});
  }
}

// dispatch({
//   type: authTypes.LOG_IN, payload: {
//     acces: data.access,
//     access_expiration: data.access_expiration,
//     refresh: data.refresh,
//     refresh_expiration: data.refresh_expiration,
//     user: data.user,
//     isAuthenticated: true,
//     chatToken: data.chat_token,          
//   }
// });