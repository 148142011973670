import axios from 'axios';
const BASE_URL = process.env.REACT_APP_ATTICUS_API_URL;

export const sendPasswordResetRequest = async (email) => {
 const URL = `${BASE_URL}/auth/password/reset/`;
  try {
    // const query = `
    //   mutation sendPasswordResetRequest {
    //     sendPasswordResetRequest(email: "${email}") {
    //       success
    //     }
    //   }
    // `;
    const headers = {
      'Content-Type': 'application/json'
    }

    const response = await axios.post(URL, {
      email
    }, headers);

    if (response.status !== 200) {
      return {
        success: false,
        data: response,
      };
    } else {
      return {
        success: true,
        data: "Successfully sent password reset request",
      }
    }
  } catch (error) {
    console.error("Error sending password reset request:", error);
    return {
      success: false,
      message: error.message,
    };
  }
}

export const resetPassword = async (password, confirmPassword, uid, token) => {
  // const query = `
  //   mutation resetPassword {
  //     resetPassword(token: "${token}", password: "${password}") {
  //       success
  //     }
  //   }
  // `;

  const url = `${BASE_URL}/auth/password/reset/confirm/`;
  const headers = {
    'Content-Type': 'application/json'
  }
  
  try {
    const response = await axios.post(url, {
      "new_password1": password,
      "new_password2": confirmPassword,
      "uid": uid,
      "token": token     
    }, headers);

    if (response.status !== 200) {
      return {
        success: false,
        data: error.message,
      };
    } else {
      return {
        success: true,
        data: response?.data?.data?.resetPassword,
      }
    }
  } catch (error) {
    console.error("Error resetting password:", error);
    return {
      success: false,
      data: error.message,
    }
  }
}
