import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DashboardPannel from './DashboardPannel/DashboardPannel';
import PageTitle from '../Shared/PageTitle/PageTitle';
import CasesPannel from './DashboardPannel/CasesPannel';
import SearchSection from './SearchSection/SearchSection';
import './Atticus.css';
import { getClosedCases, getOpenCases } from '@lib/apis/resources/case';
import Loader from '@Shared/Loader/Loader';
import { getUser } from '@lib/apis/resources/user';
import { getLegalTeamMembers } from '@lib/apis/resources/legalTeam';
import GroupAvatar from '@Shared/GroupAvatar/GroupAvatar';
import { legalTeamImages } from '../LegalTeams/LegalTeamsIndex/legalTeamData';

const Atticus = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const auth = useSelector((state) => state.auth);
  const NUMBER_OF_CASES_TO_DISPLAY = 2;

  const [closeCases, setCloseCases] = useState(null);
  const [openCases, setOpenCases] = useState(null);
  const [legalTeam, setLegalTeam] = useState(null);
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!user) {
          console.log('go to login to set user');
          return;
        }

        const email = user?.email;
        const userId = user?.cognito_user_id;
        const token = auth?.access;

        const getUserResponse = await getUser(email);
        const legalTeamId = getUserResponse?.data?.user?.legalTeamIds[0];
        
          if(!legalTeamId) {
            console.log('No legalTeamId found')
          }


        const [legalTeamResponse, closedCasesResponse, openCasesResponse] = await Promise.all([
          getLegalTeamMembers(legalTeamId),
          getClosedCases(userId, token),
          getOpenCases(userId, token)
        ]);

        setLegalTeam(legalTeamResponse?.data?.legalTeam?.userIds);
        setCloseCases(closedCasesResponse?.data?.closedCases)
        setOpenCases(openCasesResponse?.data?.openCases);

      } catch (error) {
        console.error('Error getting cases and legal team:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user, dispatch]);

  const legalTeamMembers = legalTeam?.map((userId, index) => {
    return {
      user_id: userId,
      image: legalTeamImages[index % legalTeamImages.length], 
    };
  });

  if (loading) {
    return <Loader/>;
  }
 
  return (
    <main className="dashboard-page main-page">
      <PageTitle title="Federal Criminal" subtitle="Overview" />
      <div className="dashboard-pannel-container">
        <DashboardPannel title="Your Legal Team" subtitle={`${legalTeam?.length} members`} linkText="Manage Team" linkAnchor="team">
          <GroupAvatar legalTeam={legalTeamMembers}/>
        </DashboardPannel>
        <CasesPannel showingOpenCases={true} cases={openCases} numberOfCasesToDisplay={NUMBER_OF_CASES_TO_DISPLAY} />
        <CasesPannel showingOpenCases={false} cases={closeCases} numberOfCasesToDisplay={NUMBER_OF_CASES_TO_DISPLAY} />
      </div>
      <div className="dashboard-home-search">
        <h2>Search Cases</h2>
        <SearchSection />
      </div>
    </main>
  );
};

export default Atticus;
