import React, { useState, useRef, useEffect, useContext } from 'react';
import './Argument.css';
import Header from '../../Shared/Header/Header';
import RoleSelection from '../Shared/RoleSelection/RoleSelection';
import MessageCard from '../Shared/MessageCard/MessageCard';
import { argumentData } from './aurgumentData';
import ChatInputBox from '../../Shared/ChatInputBox/ChatInputBox';
import ChatContext from '../../ChatContext';
import ProcessingMessageBubble from '../../Research/ProcessingMessageBubble/ProcessingMessageBubble';
import Message from './Message/Message';

const targetChatType = 'argument';

const Argument = () => {
  const { waitingForResponse, messages, addResponseMessage, setMessages, setConversationId } = useContext(ChatContext);
  const [selectedRole, setSelectedRole] = useState(null);
  const chatContentRef = useRef(null);

  const handleRoleChange = (roleId) => {
    setSelectedRole((prev) => (prev === roleId ? null : roleId));
  };

  const selectedRoleData = argumentData.find((role) =>  role?.id === selectedRole?.id);

  useEffect(() => {
    setConversationId(null);
    setMessages([]);
  }, []);

  useEffect(() => {
    if (chatContentRef.current) {
      chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
    }
  }, [messages, selectedRole]);

  

  return (
    <div className="chat-profile-container">
      <Header />
      <div className="chat-profile-content" ref={chatContentRef}>
        <div className="chat-profile-time">Today, 09:00 AM</div>
        <MessageCard text="Welcome to the Argument section! I can help you develop arguments for your case, whether you’re supporting a claim or refuting the prosecution’s position. What would you like to work on today?" />
        <RoleSelection roles={argumentData} selectedRole={selectedRole} onRoleChange={handleRoleChange} />

        {selectedRoleData && (
          <MessageCard text={selectedRoleData?.question}/>
        )}

      </div>

      {messages.map((message) => (
        <Message
          key={message.id}
          text={message?.text ? message.text : message?.content?.[0]?.text?.value ? message.content[0].text.value : ""}
          userIsAuthor={message.sender === "User" || message.userIsAuthor}
        />
      ))}

      {waitingForResponse && <ProcessingMessageBubble />}
      <ChatInputBox type={targetChatType} isDefaultSelected={selectedRoleData ? true : false} promptFlow={selectedRoleData?.flow} noRedirect={true} />
    </div>
  );
};

export default Argument;
