import React, { useContext } from 'react';
import './ChatHistory.css';
import { formatDate, formatToYYYYMMDD } from '@lib/utils/dateFormatHelpers';
import ChatContext from '../../ChatContext';
import { getMesssagesForConversation } from '@lib/apis/chatbot';
import { useSelector } from 'react-redux';
import { getParamsFromConversationId, getTagFromLocation } from '@lib/utils/chatbot';
import { useNavigate, useLocation } from 'react-router-dom';

// TODO: Extract these functions to a utility file
const easyDateFormat = (dateString) => {
  return dateString.split("T")[0]; // Only take the date part (ignoring time)
};

const prettyDate = (timestamp) => {
  const date = new Date(timestamp);
  const options = { year: "numeric", month: "short", day: "2-digit" };
  return date.toLocaleDateString("en-US", options);
};

const ChatHistory = () => {
  const { conversations, setMessages, setConversationId, conversationId, currentThread } = useContext(ChatContext);
  const auth = useSelector(state => state.auth);
  const navigate = useNavigate();
  const location = useLocation();

  let conversationsForCurrentThread = [];
  if (conversations) {
    conversationsForCurrentThread = conversations.filter((conversation) => {
      const threadIdToTest = getParamsFromConversationId(conversation?.conversationId)?.threadId;
      const conversationParams= getParamsFromConversationId(conversation?.conversationId);
      return conversationParams.threadId === `${currentThread?.threadId}` && conversationParams.tag === getTagFromLocation(location);
    });
  }
  const today = new Date().toISOString().split('T')[0];
  const yesterday = new Date(Date.now() - 864e5).toISOString().split('T')[0];

  const handleClick = async (conversationId) => {
    const response = await getMesssagesForConversation(conversationId, auth.chatToken);
    const correctLocation = `/chat/case/${currentThread?.caseId}/research`
    // if (window.location.pathname !== correctLocation) {
    //   navigate(correctLocation);
    // }
    setMessages(response.data);
    setConversationId(conversationId);
  };

  const getFilteredChatsByDate = (date) => {
    if (!conversationsForCurrentThread || conversationsForCurrentThread.length === 0) return [];
    return conversationsForCurrentThread.filter((chat) => easyDateFormat(chat.updatedAt) === date);
  };

  const groupChatsByDate = (chats) => {
    return chats.reduce((acc, chat) => {
      const chatDate = easyDateFormat(chat.updatedAt); // Get date part only
      acc[chatDate] = acc[chatDate] || [];
      acc[chatDate].push(chat);
      return acc;
    }, {});
  };

  // Ensure that we exclude today and yesterday from earlier chats
  const getEarlierChats = () => {
    if (!conversationsForCurrentThread || conversationsForCurrentThread.length === 0) return [];
    return conversationsForCurrentThread.filter((chat) => {
      const chatDate = easyDateFormat(chat.updatedAt);
      return chatDate !== today && chatDate !== yesterday;
    });
  };

  const renderChatItems = (groupedChats) => {
    let lastPrintedDate = null;

    return Object.keys(groupedChats).map((date) => {
      const shouldPrintDate = date !== lastPrintedDate;
      if (shouldPrintDate) lastPrintedDate = date;

      return (
        <div key={date}>
          {shouldPrintDate && <h4 className="chat-history-date-label">{prettyDate(date)}</h4>}
          {groupedChats[date].map((chat, index) => (
            <div 
              onClick={() => handleClick(chat.conversationId)} 
              className={`chat-history-item ${conversationId === chat.conversationId ? 'active-conversation' : ''}`} 
              key={index}
            >
            {chat?.title?.length > 25 ? `${chat?.title?.slice(0, 25)}...` : chat.title}
            </div>
          ))}
        </div>
      );
    });
  };

  const renderChats = (date, label) => {
    const filteredChats = getFilteredChatsByDate(date);
    if (filteredChats.length === 0) return null;

    return (
      <div key={date}>
        <h4 className="chat-history-date-label">{label}</h4>
        {filteredChats.map((chat, index) => (
          <div 
            onClick={() => handleClick(chat.conversationId)} 
            className={`chat-history-item ${conversationId === chat.conversationId ? 'active-conversation' : ''}`} 
            key={index}
          >

            {chat?.title?.length > 25 ? `${chat?.title?.slice(0, 25)}...` : chat.title}
          </div>
        ))}
      </div>
    );
  };

  const renderEarlierChats = () => {
    const earlierChats = getEarlierChats(); // Get filtered chats
    if (earlierChats.length === 0) return null;

    const groupedChats = groupChatsByDate(earlierChats); // Group chats by date
    return renderChatItems(groupedChats); // Render grouped chats
  };

  return (
    <div className="chat-history-container">
      <div className="chat-history-header">
        <h2>Chat History</h2>
        <button className="chat-history-add-chat-button">+</button>
      </div>

      <div className="chat-history-list">
        {renderChats(today, 'Today')}
        {renderChats(yesterday, 'Yesterday')}
        {renderEarlierChats()}
      </div>
    </div>
  );
};

export default ChatHistory;
