/* eslint-disable no-constant-condition */
import axios from 'axios';
import axiosInstance from './axiosInstance';
import { createPacerData } from '../../graphql4/mutations';
import cases from '../mocks/cases';
import { generateClient } from './api-client'; // Ensure the path is correct
import { First } from 'react-bootstrap/esm/PageItem';
import motionDictionary from '@lib/utils/motionDictionary.js';
import { extractBucketName } from '@lib/utils/general';
import { retryApiCall } from '../utils/caseHelpers';
import { getCaseSummary } from './resources/case';
import { getTodayYYYYMMDD } from '@lib/utils/dateFormatHelpers';
import { motion } from 'framer-motion';


// const BASE_URL = 'https://juristaiapi.wrmlabs.com/user/search'; THIS IS NOT CORRECT

const APP_ID = process.env.REACT_APP_APP_ID;

// Search by ID function

export const searchById = async (id, token) => {
  // TODO: Remove this mock data when the API is ready
  if (false) {
    // Change to `false` to bypass mock data when the API is ready
    return [cases[0]];
  }
  try {
    const response = await axios.get(`${BASE_URL}?id=${id}&token=${token}`, {
      maxRedirects: 5,
    });
    const data = await response.data;
    return data;
  } catch (error) {
    return error;
  }
};

// Advanced search function
export const advancedSearch = async (searchParams) => {
  try {
    const LAMBDA_URL = 'https://um8055uehb.execute-api.us-east-1.amazonaws.com/devv1/CLIDDocketSearch';
    const headers = {
      'Content-Type': 'application/json',
    };

    // Build the payload based on the Lambda's logic:
    // 1. Include appId (required)
    // 2. Set defaults for "type" and "order_by"
    // 3. Include any of the other parameters if provided
    const payload = {
      appId: process.env.REACT_APP_APP_ID, // required by Lambda
      type: searchParams.type || "r",        // default to Federal case dockets
      order_by: searchParams.order_by || "score desc",
      // Include search criteria; the Lambda will prioritize:
      // - caseNumberFull if present
      // - then q if present
      // - then name if present
      // - otherwise it will build an advanced search from the other fields
      ...(searchParams.caseNumberFull && { caseNumberFull: searchParams.caseNumberFull }),
      ...(searchParams.q && { q: searchParams.q }),
      ...(searchParams.name && { name: searchParams.name }),
      ...(searchParams.lastName && { lastName: searchParams.lastName }),
      ...(searchParams.firstName && { firstName: searchParams.firstName }),
      ...(searchParams.caseTitle && { caseTitle: searchParams.caseTitle }),
      ...(searchParams.courtId && { courtId: searchParams.courtId }),
      ...(searchParams.dateFiledFrom && { dateFiledFrom: searchParams.dateFiledFrom }),
      ...(searchParams.natureOfSuit && { natureOfSuit: searchParams.natureOfSuit }),
      ...(searchParams.next && { next: searchParams.next }),
    };

    // Wrap the payload as expected by your Lambda (a JSON string in a "body" field)
    const requestBody = { body: JSON.stringify(payload) };

    const response = await axios.post(LAMBDA_URL, requestBody, { headers });
    if (response.status !== 200) {
      return { success: false, data: 'Search API request failed.' };
    }
    const jsonData = JSON.parse(response.data.body);
    return { success: true, data: jsonData };
  } catch (error) {
    return { success: false, data: 'There was a problem with your search.' };
  }
};

export const getCaseMetadataFromDocketId = async (docketId) => {
  try {
    const LAMBDA_URL = 'https://qcacbzrrf2.execute-api.us-east-1.amazonaws.com/devv1/CaseMetadataDocketIDCL';
    const headers = { 'Content-Type': 'application/json' };
    const body = { body: JSON.stringify({ docketId, appId: APP_ID }) };
    const response = await axios.post(LAMBDA_URL, body, { headers });

    if (response.status !== 200) {
      return { success: false, data: 'getCaseMetadataFromDocketId failed.' };
    }
    const jsonData = JSON.parse(response.data.body);
    return { success: true, data: jsonData };
  } catch (error) {
    return { success: false, data: 'There was a problem with your search.' };
  }
};

export const addCaseS3creationJS = async (docketId) => {
  try {
    const LAMBDA_URL =
        'https://2ko4or4dr3.execute-api.us-east-1.amazonaws.com/devv1/createbucket';
    const headers = {
      'Content-Type': 'application/json',
    };

    // Build a payload object with the required field.
    const payload = { caseId: docketId };

    // Wrap the payload in a "body" field as a JSON string.
    const requestBody = { body: JSON.stringify(payload) };

    const response = await axios.post(LAMBDA_URL, requestBody, { headers });

    // Validate the response status.
    if (response.status !== 200 || response.data.statusCode !== 200) {
      return { success: false, data: 'addCaseS3creationJS failed.' };
    }

    // Parse the response body from Lambda.
    const data = JSON.parse(response.data.body);
    return { success: true, data };
  } catch (error) {
    return { success: false, data: 'There was a problem with your action.' };
  }
};

export const getDocumentsFromDocketId = async (docketId) => {
  try {
    const LAMBDA_URL =
        'https://eefyhgz6sa.execute-api.us-east-1.amazonaws.com/devv1/doc-txt-rtrvr';
    const headers = { 'Content-Type': 'application/json' };

    // Build the payload with docketId and appId.
    const payload = { docketId, appId: APP_ID };
    // Wrap the payload in a "body" key as a JSON string.
    const requestBody = { body: JSON.stringify(payload) };

    const response = await axios.post(LAMBDA_URL, requestBody, { headers });

    // Check if the Lambda returned a valid response.
    if (response.status !== 200 || response.data.statusCode !== 200) {
      return { success: false, data: 'getDocumentsFromDocketId failed.' };
    }

    // Parse the JSON string returned by the Lambda.
    const data = JSON.parse(response.data.body);
    return { success: true, data };
  } catch (error) {
    return { success: false, data: 'There was a problem with your search.' };
  }
};


export const updateCaseInDnmoDBCaseTable = async (docketId) => {
  try {
    const LAMBDA_URL =
        'https://bidecqei1i.execute-api.us-east-1.amazonaws.com/devv1/casetableupdate';
    const headers = { 'Content-Type': 'application/json' };

    // Convert docketId to string before sending it
    const payload = { docketId: String(docketId), appId: APP_ID };
    const body = { body: JSON.stringify(payload) };

    const response = await axios.post(LAMBDA_URL, body, { headers });

    if (response.status !== 200) {
      return { success: false, data: 'updateCaseInDnmoDBCaseTable failed.' };
    }

    const data = JSON.parse(response.data.body);
    return { success: true, data };
  } catch (error) {
    return { success: false, data: 'There was a problem with your action.' };
  }
};



export const retrieveCaseTimeline = async (docketId) => {
  try {
    const LAMBDA_URL = 'https://tgr0b1064e.execute-api.us-east-1.amazonaws.com/devv1/DocketMetadataRetriever';
    const headers = { 'Content-Type': 'application/json' };
    const body = { body: JSON.stringify({ docketId, appId: APP_ID }) };
    const response = await axios.post(LAMBDA_URL, body, { headers });

    if (response.status !== 200) {
      return { success: false, data: 'retrieveCaseTimeline failed.' };
    }
    const data = response.data.body;
    return { success: true, data };
  } catch (error) {
    return { success: false, data: 'There was a problem with your search.' };
  }
};

export const uploadCaseDocument = async (userId, caseId) => {
  try {
    const LAMBDA_URL = 'https://7z7erimcph.execute-api.us-east-1.amazonaws.com/devv1/upload-docs';
    const headers = { 'Content-Type': 'application/json' };
    const body = `{"userId": "${userId}", "caseId": "${caseId}"}`;
    const response = await axios.post(LAMBDA_URL, body, { headers });

    if (response.status !== 200) {
      return { success: false, data: 'uploadCaseDocument failed.' };
    }
    const data = JSON.parse(response.data.body);
    return { success: true, data };
  } catch (error) {
    return { success: false, data: 'There was a problem with your action.' };
  }
};

export const assistantThreadCreator = async (
    userId,
    caseId,
    applicationId,
    isGeneralThread,
    threadDetails
) => {
  try {
    const LAMBDA_URL =
        'https://2qvphtunaf.execute-api.us-east-1.amazonaws.com/devv1/asstthreadcrtr';
    const headers = { 'Content-Type': 'application/json' };

    // Build the payload with the required parameters.
    const payload = {
      userId: String(userId),
      caseId: String(caseId),
      applicationId: String(applicationId),
      isGeneralThread: String(isGeneralThread),
      threadDetails: String(threadDetails),
    };

    // Send the payload directly without additional wrapping.
    const response = await axios.post(LAMBDA_URL, payload, { headers });

    // Check for a valid response.
    if (response.status !== 200 || response.data.statusCode !== 200) {
      return { success: false, data: 'assistantThreadCreator failed.' };
    }
    // Parse the JSON-stringified body returned by the Lambda.
    const data = JSON.parse(response.data.body);
    return { success: true, data };
  } catch (error) {
    return { success: false, data: 'There was a problem with your action.' };
  }
};


export const docketClassification = async (userId, caseId) => {
  try {
    const LAMBDA_URL = 'https://exrv5a7d3a.execute-api.us-east-1.amazonaws.com/devv1/crim-docket-class';
    const headers = { 'Content-Type': 'application/json' };
    const body = JSON.stringify({ userId: String(userId), caseId: String(caseId), appId: APP_ID });
    const response = await axios.post(LAMBDA_URL, body, { headers });

    if (response.status !== 200 || response.data.statusCode !== 200) {
      return { success: false, data: 'docketClassification failed.' };
    }
    const data = response.data.body;
    return { success: true, data };
  } catch (error) {
    return { success: false, data: 'There was a problem with your action.' };
  }
};

export const createS3BucketForUserAndCase = async (userId, caseId) => {
  try {
    const LAMBDA_URL =
        'https://qwsmd1hys0.execute-api.us-east-1.amazonaws.com/devv1/user-case-s3';
    const headers = { 'Content-Type': 'application/json' };

    // Build the payload with only the required keys.
    const payload = {
      userId: userId,
      caseId: caseId,
    };

    // Send the payload directly.
    const response = await axios.post(LAMBDA_URL, payload, { headers });

    if (response.status !== 200 || response.data.statusCode !== 200) {
      return { success: false, data: 'createS3BucketForUserAndCase failed.' };
    }

    // Extract the bucket name from the response body.
    const data = response.data;
    const bucketName = extractBucketName(data.body);
    return { success: true, data: { ...data, bucketName } };
  } catch (error) {
    return { success: false, data: 'There was a problem with your action.' };
  }
};

export const updateActivityLogFile = async (bucketName) => {
  try {
    const LAMBDA_URL = 'https://18jazgkwdg.execute-api.us-east-1.amazonaws.com/devv1/bucket-log';
    const headers = { 'Content-Type': 'application/json' };
    const body = { body: JSON.stringify({ bucket_name: bucketName }) };
    const response = await axios.post(LAMBDA_URL, body, { headers });

    if (response.status !== 200 || response.data.statusCode !== 200) {
      return { success: false, data: 'updateActivityLogFile failed.' };
    }
    const data = response.data.body;
    return { success: true, data };
  } catch (error) {
    return { success: false, data: 'There was a problem with your action.' };
  }
};

// Summarize a single docket document (placeholder/mocked)
export const summarizeDocument = async (caseId, userId, docketEntryId) => {
  try {
    // Example only; your real code will differ
    const LAMBDA_URL = 'MOCK';
    const headers = { 'Content-Type': 'application/json' };
    const body = { caseId, userId, docketEntryId };

    // const response = await axios.post(LAMBDA_URL, body, { headers });
    // const data = response.data;
    const data =
        'The criminal complaint against Donald Trump alleges that he conspired... [mock example]';

    return { success: true, data };
  } catch (error) {
    return { success: false, data: 'There was a problem with your action.' };
  }
};

// Generate recommended motions
export const generateDocketRecommendations = async (userId, caseId, appId = APP_ID) => {
  try {
    const LAMBDA_URL =
        'https://dy8hfa9zai.execute-api.us-east-1.amazonaws.com/devv1/crim-recom';
    const headers = { 'Content-Type': 'application/json' };
    const body = { body: `{"userId": "${userId}", "caseId": "${caseId}", "appId": "${appId}"}` };
    const response = await axios.post(LAMBDA_URL, body, { headers });

    if (response.status !== 200 || response.data.statusCode !== 200) {
      return { success: false, data: 'generateDocketRecommendations failed.' };
    }
    const responseData = response.data.body;
    const jsonData = JSON.parse(responseData);

    let recommendedMotionsString;
    if (jsonData?.recommended_motions?.includes('```json')) {
      recommendedMotionsString = jsonData?.recommended_motions.replace(
          /```json\n|\n```/g,
          ''
      );
    } else {
      recommendedMotionsString = jsonData?.recommended_motions;
    }

    const docketRecommendations = JSON.parse(recommendedMotionsString);
    const cleanRecommendations = cleanMotions(docketRecommendations?.recommended_motions);

    const motion1 = cleanRecommendations?.Motion1;
    const motion2 = cleanRecommendations?.Motion2;
    const motion3 = cleanRecommendations?.Motion3;
    const data = [
      { value: motion1, name: motionDictionary[motion1] },
      { value: motion2, name: motionDictionary[motion2] },
      { value: motion3, name: motionDictionary[motion3] },
    ];
    return { success: true, data };
  } catch (error) {
    return { success: false, data: 'There was a problem with your action.' };
  }
};

const cleanMotions = (motions) => {
  if (!motions) {
    console.log('Motions are undefined or null');
    return {};
  }
  return {
    Motion1: motions.Motion1,
    Motion2: motions.Motion2,
    Motion3: motions.Motion3,
  };
};

export const summarizeCase = async (userId, caseId, appId, useLambdaApi = false) => {
  try {
    // If not using the Lambda, attempt a local "getCaseSummary"
    if (!useLambdaApi) {
      const summaryS3Url = await getCaseSummary(caseId);
      if (summaryS3Url?.success) {
        return { success: true, data: { summary: summaryS3Url.data } };
      }
    }
    // Otherwise call a Summarize-Case Lambda
    const LAMBDA_URL = 'https://api-dev.juristai.org/api/core/generate-case-summary/';

     const body = {
       userId: userId,
       caseId: caseId,
       appId: appId
     };

    // const body = { body: `{"userId": "${userId}", "caseId": "${caseId}", "appId": "${appId}"}` };
    const response = await axiosInstance.post(LAMBDA_URL, body);

    if (response.status !== 200 || response.data.statusCode !== 200) {
      return { success: false, data: 'summarizeCase failed.' };
    }
    const data = JSON.parse(response.data.body);

    return { success: true, data };
  } catch (error) {
    return { success: false, data: 'There was a problem with your action.' };
  }
};

// Add a case to PACER
export const addCase = async ({
                                caseId,
                                courtId,
                                dateFiled,
                                caseTitle: caseName,
                                caseNumber: docketNumber,
                                assignedTo = "",
                                dateLastFiling = "",
                                dateTerminated = "",
                                numberOfDocuments = 0,
                                numDocketEntries = 0,
                                summaryS3Url = ""
                              }) => {
  try {
    const client = generateClient(); // Make sure this is correctly implemented
    const newPacerData = await client.graphql({
      query: createPacerData,
      variables: {
        input: {
          caseId,
          appId: process.env.REACT_APP_APP_ID, // Ensure this is set
          assignedTo,
          caseName,
          courtId,
          dateFiled,
          dateLastFiling,
          dateTerminated,
          docketNumber,
          numberOfDocuments,
          numDocketEntries,
          summaryS3Url
        },
      },
    });
    console.log('newPacerData', newPacerData);
    // Return only the data payload (if your dashboard expects that)
    return { success: true, data: newPacerData.data };
  } catch (error) {
    console.error("GraphQL error:", error);
    return { success: false, data: 'There was a problem with your action.' };
  }
};


// Helper function for adding a new docket/case
export const handleAddCaseApisFunc = async (
    userId,
    docketId,
    loadingMessage,
    setLoadingMessage
) => {
  await retryApiCall(addCaseS3creationJS, [docketId], loadingMessage, setLoadingMessage);
  const createBucketApiResponse = await retryApiCall(
      createS3BucketForUserAndCase,
      [userId, docketId],
      loadingMessage,
      setLoadingMessage
  );
  const bucketName = createBucketApiResponse?.data?.bucketName;
  await retryApiCall(getDocumentsFromDocketId, [docketId], loadingMessage, setLoadingMessage);
  await retryApiCall(updateActivityLogFile, [bucketName], loadingMessage, setLoadingMessage);
  return true;
};

// Helper function for docket/case APIs
export const handleDocketCaseApisFunc = async (
    userId,
    caseId,
    applicationId,
    isGeneralThread,
    threadDetails
) => {
  await assistantThreadCreator(userId, caseId, applicationId, isGeneralThread, threadDetails);
  await uploadCaseDocument(userId, caseId);
  await docketClassification(userId, caseId);
};

// Get the summary URL for a docket entry
export const getDocketSummaryUrl = async (caseId, userId, docketEntryId) => {
  try {
    const LAMBDA_URL = 'https://6udx45gs6a.execute-api.us-east-1.amazonaws.com/devv1/doc-sum';
    const headers = { 'Content-Type': 'application/json' };
    const body = { body: JSON.stringify({ caseId, userId, docketEntryId, appId: APP_ID }) };
    const response = await axios.post(LAMBDA_URL, body, { headers });

    if (response.status !== 200 || response.data.statusCode !== 200) {
      const errorMessage = JSON.parse(response.data.body)?.error;
      const isNotAvailable = errorMessage === 'Failed to fetch document content from S3.';
      return {
        success: false,
        isNotAvailable,
        data: 'There was a problem with your action.',
      };
    }
    const data = JSON.parse(response.data.body);
    return { success: true, data };
  } catch (error) {
    return { success: false, data: 'There was a problem with your action.' };
  }
};

// ---------------------------------------------------
// Brief-generation, queryPrecedents, etc. functions
// ---------------------------------------------------

export const generateDocumentLambda = async (userId, caseId, motionType, userInput) => {
  try {
    const LAMBDA_URL = "https://api-dev.juristai.org/api/core/generate-motion/";

    const body = {
        userId: userId,
        caseId: caseId,
        motion_type: motionType,
        support: userInput,
        appId: APP_ID,
        date: getTodayYYYYMMDD(),
      }

    const response = await axiosInstance.post(LAMBDA_URL, body);   
    if (response?.data?.statusCode !== 200) {
      return { success: false, data: 'There was a problem with your action. No 200 code' };
    }
    const data = JSON.parse(response.data.body);
    return { statusCode: response.status, success: true, data };
  } catch (error) {
    console.log('error', error);
    return { success: false, error, data: 'There was a problem with your action. No 200 code' };
  }
}

export const queryPrecedents = async (userId, caseId, documentName, motionId) => {
  try {
    const LAMBDA_URL = 'https://5y3pmv39nf.execute-api.us-east-1.amazonaws.com/devv1/prec-query';
    const headers = {
      'Content-Type': 'application/json',
    };

    const requestBody = {
      userId: userId,
      caseId: caseId,
      documentName: documentName,
      motionId: motionId,
      appId: APP_ID
    };

    const requestBodyString = { body: JSON.stringify(requestBody)}
    const response = await axios.post(LAMBDA_URL, requestBodyString, { headers });
    if (response?.data?.statusCode !== 200) {
      return { success: false, data: 'There was a problem with your action. No 200 code' };
    }

    const data = JSON.parse(response.data.body);
    return { success: true, data: data };
  } catch (error) {
    return { success: false, data: 'There was a problem with your action.', error: error };
  }
};

export const processPrecedents = async (userId, caseId, motionId, documentName, citations) => {
  try {
    const LAMBDA_URL = "https://i8s0lbxa8d.execute-api.us-east-1.amazonaws.com/devv1/query-process";

    const body = {
      body: JSON.stringify({
            userId: userId,
            caseId: caseId,
            motionId: motionId,
            documentName: documentName,
            citations: citations,
          }
      )};
    const response = await axios.post(LAMBDA_URL, body, { headers: { 'Content-Type': 'application/json' } });
    if (response?.data?.statusCode !== 200) {
      return { success: false, data: 'There was a problem with your action. No 200 code' };
    }
    const data = JSON.parse(response.data.body);
    return {
      success: true,
      data: data,
    }
  } catch (error) {
    return { success: false, data: 'There was a problem with your action.', error: error };
  }
}

export const hallucinationDetection = async (userId, caseId, motionId, documentName, citations) => {
  try {
    const LAMBDA_URL = 'https://1cewqqicf6.execute-api.us-east-1.amazonaws.com/devv1/halluc-detect';
    const headers = {
      'Content-Type': 'application/json',
    };

    const body = {
      userId: userId,
      caseId: caseId,
      motionId: motionId,
      documentName: documentName,
      processed_citations: citations,
    };
    const response = await axios.post(LAMBDA_URL, { "body": JSON.stringify(body) }, { headers });
    console.log('hallucinationDetection ---- response', response);
    if (response?.data?.statusCode !== 200) {
      return { success: false, data: 'There was a problem with your action. No 200 code' };
    }
    const data = JSON.parse(response.data.body);
    return { success: true, data: data };
  } catch (error) {
    return { success: false, data: error };
  }
}

export const generateDocumentStepFunction = async (userId, caseId, motionType, userInput) => {
  const appId = process.env.REACT_APP_APP_ID;
  const responseGenerateDocumentLambda = await generateDocumentLambda(userId, caseId, motionType, userInput);
  const { documentName, motionId } = responseGenerateDocumentLambda.data;
  const responseQueryPrecedents = await queryPrecedents(userId, caseId, documentName, motionId, appId);
  const citations = responseQueryPrecedents.data.citations;
  const responseProcessPrecedents = await processPrecedents(userId, caseId, motionId, documentName, citations);
  const { processed_citations:processedCitations } = responseProcessPrecedents.data;
  const hallucinationDetectionResponse = await hallucinationDetection(userId, caseId, motionId, documentName, processedCitations);
  return hallucinationDetectionResponse;
}