import React from 'react';
import './NavBarListItem.css';
import { NavLink } from 'react-router-dom';
import NotificationBadge from '../../NotificationBadge/NotificationBadge';

const NavBarListItem = ({ icon: Icon, text, url, target, notifications = 0 }) => {
  return (
    <NavLink to={url} end activeClassName="active" target={target}>
    <li className={`nav-bar-list-item`}>
      <Icon />
      <p>{text}</p>
      {notifications > 0 && <NotificationBadge count={notifications} className={"danger"} style={{position: "absolute", right: "1.25rem"}} />}
    </li>
    </NavLink>
  )
}

export default NavBarListItem;
