import axios from 'axios';
import store from '@state/store';
import { refreshToken, logOut } from '@state/actions/authActions';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_ATTICUS_API_URL,
});

const handleLogout = () => {
  store.dispatch(logOut());
};

axiosInstance.interceptors.request.use(
  (config) => {
    const token = store.getState().auth.acces;

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
     handleLogout();
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
