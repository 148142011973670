/* eslint-disable no-unreachable */
/* eslint-disable no-undef */

import axios from 'axios';
import users from '../../mocks/users';
import { getAuthHeaders, graphQlUrl } from '../utilities';
import { getCase } from './case';

export const getUser = async (email) => {
  try {
    const query = `
      query MyQuery {
        user(email: "${email}") {
          assignedCases
          userId
          organizationId
          username
          email
          firstName
          lastName
          createdDate
          legalTeamIds
        }
      }
    `;

    const encodedQuery = encodeURIComponent(query); // Encode the query for safe URL usage
    const URL = `${graphQlUrl}${encodedQuery}`;
    const response = await axios.get(URL);
    return {
      success: true,
      data: response.data.data
    }; // Return the user data
  } catch (error) {
    console.error('Error getting user:', error);
    return { success: false, message: error.message };
  }
};

// UPDATE
export const updateUser = async (id, params, token) => {

  return Object.keys(params).length === 0 ? users[0] : { ...users[0], ...params }; // Mocked response. If given params, return the params plus an user info, otherwise return a mock user
  try {
    const response = await axios.put(`${BASE_URL}/users/${id}`, params, getAuthHeaders(token));
    const { user } = response.data;
    return user;
  } catch (error) {
    console.error('Error updating user:', error);
    return error;
  }
}

// DELETE
export const deleteUser = async (id, token) => {

  return users[0]; // Mocked response. Always return the first user
  try {
    const response = await axios.delete(`${BASE_URL}/users/${id}`, getAuthHeaders(token));
    const { user } = response.data;
    return user;
  } catch (error) {
    console.error('Error deleting user:', error);
    return error;
  }
}

export const addCaseToUser = async (userId, caseId, token) => {
  try {
    // const query = `
    //     query MyQuery {
    //       docketEntryByCase(caseId: "${caseId}") {
    //         docketEntryId
    //         caseId
    //         dateTime
    //         entryDetails
    //         classification
    //         dateCreated
    //         dateFiled
    //         description
    //         documentExists
    //         entryNumber
    //         pacerSequenceNumber
    //         resourceUri
    //          summaryS3Url
    //       }
    //     }
    // `;

    const query = `
      mutation MyMutation {
        assignUserToCase(caseId: "${caseId}", userId: "${userId}") {
          user {
            userId
            organizationId
            username
            email
            firstName
            lastName
            createdDate
          }
          case {
            caseId
            appId
            docketNumber
            caseName
            courtId
            dateFiled
            dateLastFiling
            dateTerminated
            documentNumber
            numberOfDocuments
            numDocketEntries
            summaryS3Url
            }
          }
        }
    `;


    const encodedQuery = encodeURIComponent(query);
    const response = await axios.post(graphQlUrl, { query});
    console.log("addCaseToUser", response);
    return {
      success: true,
      data: {
        user: response.data.data.assignUserToCase.user,
        case: response.data.data.assignUserToCase.case
      }
    }; // Return the case data
  } catch (error) {
    console.error('Error getting docket entries for case:', error);
    return {
      success: false,
      message: error.message
    };
  }
}

// Refactor this
export const getCasesForUser = async (email, token) => {
    try {
      const query = `
        query UsersAssignedCases {
          user(email: "${email}") {
            userCases {
              caseId
              appId
              docketNumber
              caseName
              courtId
              dateFiled
              dateLastFiling
              dateTerminated
              documentNumber
              numberOfDocuments
              numDocketEntries
              summaryS3Url
            }
          }
        }
    `;
  
      const response = await axios.post(graphQlUrl, { query });
      const data = response?.data?.data?.user?.userCases || [];
      const filteredCases = data.filter((item) => item.appId === process.env.REACT_APP_APP_ID);

      return {
        success: true,
        data: filteredCases,
      };
    } catch (error) {
      console.error('Error getting cases for user:', error);
      return {
        success: false,
        message: error.message
      }
    }
}

export const getOrganizationDetails = async (organizationId) => {
  try {
    const query = `
      query MyQuery {
        organization(organizationId: "${organizationId}") {
          organizationId
          organizationName
          orgUsers {
            userId
            email
            firstName
            lastName
            assignedCases
          }
        }
      }
    `;

    const response = await axios.post(graphQlUrl, { query });
    const data = response.data.data.organization;
    return {
      success: true,
      data: data,
    };
  } catch (error) {
    console.error("Error getting organization details:", error);
    return {
      success: false,
      message: error.message,
    };
  }
};



export const orgUsersWithAppAccess = async (organizationId) => {
  try {
    const query = `
      query MyQuery {
        orgUsersWithAppAccess(organizationId: "${organizationId}") {
          accessLevel
          appId
          expirationDate
          grantedDate
          organizationId
          userId
        }
      }
    `;

    const response = await axios.post(graphQlUrl, { query });
    const data = response?.data?.data?.orgUsersWithAppAccess;
    return {
      success: true,
      data: data,
    };
  } catch (error) {
    console.error("Error getting orgUsersWithAppAccess:", error);
    return {
      success: false,
      message: error.message,
    };
  }
};



