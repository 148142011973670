import React, { useContext, useState } from "react";
import "./Message.css";
import { ReactComponent as AtticusIcon } from "@icons/atticus-chat-room-frame.svg";
import { ReactComponent as Reload } from "@icons/reload.svg";
import { ReactComponent as Clipboard } from "@icons/clipboard.svg";
import checkLine  from "@assets/images/atticus/icons/check-line-green.svg"
import { formatChatResponse } from "@lib/utils/chatbot";
import ChatContext from "../../ChatContext";
import { sendMessage } from "@lib/apis/chatbot";
import { useSelector } from "react-redux";
import { STANDARD_ERROR_MESSAGE } from '@lib/utils/chatbot';

const Message = ({ text, userIsAuthor, speed = 0 }) => {
  const { lastParams, setWaitingForResponse, addResponseMessage, conversationId, parentMessageId } = useContext(ChatContext);
  const auth = useSelector(state => state.auth);
  const [isCopied, setIsCopied] = useState(false);
  
  const handleRefreshRequest = async () => {
    if (!conversationId) return;

    setWaitingForResponse(true);

    const refreshedParams = {
      ...lastParams,
      conversationId: conversationId,
      parentMessageId: parentMessageId,
    };

    let response;

    try {
      response = await sendMessage(refreshedParams, auth.chatToken);
    } catch (error) {
      addResponseMessage(STANDARD_ERROR_MESSAGE, "global", false);
    }

    setWaitingForResponse(false);

    if (response?.success) {
      const returnedMessage = response.data;
      addResponseMessage(returnedMessage, "global", false);
    } else {
      addResponseMessage(STANDARD_ERROR_MESSAGE, "global", false);
    }
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 1000);
  };

  
  if (userIsAuthor) {
    return (
      <div className="chat-research-message user-chat-message">
        <p>{(text && text?.split('|')[1]) || text}</p>
      </div>
    );
  } else {
    return (
      <div className="chat-research-message">
        <AtticusIcon className="atticus-icon" />
         <p dangerouslySetInnerHTML={{ __html: formatChatResponse(text) }}></p>
        <div className="chat-research-message-actions">
          {isCopied 
            ? <img src={checkLine} className="check-line-icon" alt="" />
            : <Clipboard className="clipboard-icon" onClick={handleCopyToClipboard} title="Copy to Clipboard" />
          }
          <Reload className="reload-icon" title="Refresh" onClick={handleRefreshRequest} />
        </div>
      </div>
    );
  }
};

export default Message;
