import React, { useState, useEffect } from "react";
import Button from "@Shared/Button/Button";
import TextField from "@Shared/TextField/TextField";
import LoaderSpinner from "@Shared/LoaderSpinner/LoaderSpinner";
import './ResetPassword.css';
import { useParams } from "react-router-dom";
import { resetPassword } from "@lib/apis/password-reset";
import { Link } from "react-router-dom";
import { success, error } from '@lib/utils/toast'

const ResetPassword = () => {
  const [formData, setFormData] = useState(
    { 
      password: "",
      passwordConfirm: ""
    });
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [showButton, setshowButton] = useState(true);
  const token = useParams().token;
  const uid = useParams().uid;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleClick = async (e) => {
    e.preventDefault();
    if (!formData.password) {
      setResponseMessage("Please provide a password.");
      return;
    }
    if (formData.passwordConfirm !== formData.password) {
      setResponseMessage("Passwords do not match.");
      return;
    }
    setLoading(true);
    const response = await resetPassword(formData.password, formData.passwordConfirm, uid, token);
    if (response.success) {
      setResponseMessage("Password successfully reset. Please log in.");
      success("Password successfully reset. Redirecting to log in page.");
      setTimeout(() => {
        window.location.href = '/login';
      }, 3000);

    } else {
      setResponseMessage("Error resetting password. Please try again.");
      error("Error resetting password. Please try again.")
    }
    setLoading(false);
    setshowButton(false);
  };

  return (
    <main className="reset-password-page">
    <div className='gradient-color-background-right'></div>

    <section className="rp-right">
      <div className="rp-form-container">
        <form>
          <h1>Reset Password</h1>
          <TextField
            placeholder="Password"
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
          />
          <TextField
            placeholder="Confirm Password"
            type="password"
            name="passwordConfirm"
            value={formData.passwordConfirm}
            onChange={handleChange}
          />
          { showButton ? (
          <Button type="submit" onClick={handleClick} className="button--primary rp-page-button" disabled={loading}>
            {loading
              ? <LoaderSpinner messages={''} hideText={false} color='var(--color-black)' width={20} /> 
              : 'Reset Password'
            }
          </Button>
          ) : ( 
            <Link to="/login">
              <Button className="button--primary log-in">Log In</Button>
            </Link>
          )
          }
          <p className="rp-response-message">{responseMessage}</p>
        </form>
      </div>
    </section>
  </main>
  );
}

export default ResetPassword;