import React from "react";
import "./PartnerSection.css";
import valenzuelaLawFirm from "@assets/images/atticus/partnerLogo/vlf-logo-black.svg";
import lexwayLawfirm from "@assets/images/atticus/partnerLogo/lexway-lawfirm.svg";
import pactLawOffice from "@assets/images/atticus/partnerLogo/pact-law-office.svg";
import estepationLawFirm from "@assets/images/atticus/partnerLogo/estepation-law-firm.svg";
import theDepewLawFirm from "@assets/images/atticus/partnerLogo/the-depew-law-firm.svg";
import fioreLawFirm from "@assets/images/atticus/partnerLogo/fiore-law-firm.svg";

const PartnerSection = () => {
  const partnerData = [
    {
      img: valenzuelaLawFirm,
      alt: "Valenzuela Law Firm Logo",
      link: 'https://www.valenzuela-law.com/',
    },
    {
      img: lexwayLawfirm,
      alt: "Lexway Lawfirm Logo",
    },
    {
      img: pactLawOffice,
      alt: "Pact Law Office",
    },
    {
      img: fioreLawFirm,
      alt: "Fiore Law Firm",
    },
    {
      img: estepationLawFirm,
      alt: "Estepation Law Firm",
    },
    {
      img: theDepewLawFirm,
      alt: "theDepewLawFirm",
    },
  ];

  return (
    <section className="partner-section-container">
      <h6 className="partner-section-heading">These firms trust JuristAI</h6>
      <div className="partner-logos">
        {partnerData.map((partner, index) => (
          <>
            <a href={partner.link} target={partner.link !== "#" ? "_blank" : "_self"}>
              <img src={partner.img} alt={partner.alt} />
            </a>
            {index !== partnerData.length - 1 && <span className="partner-border"></span>}
          </>
        ))}
      </div>
    </section>
  );
};

export default PartnerSection;
