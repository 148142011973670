import React, { useContext, useEffect, useState } from 'react';
import GenerateMotionModalPage from './GenerateMotionModalPage';
import SharedButton from '@Shared/Button/Button';
import GenerateMotionContext from '../../GenerateMotionContext';
import './MotionModalPages.css';
import { generateDocumentLambda, queryPrecedents, processPrecedents } from '@lib/apis/pacer-api';
import md from '@lib/mocks/motionGenerationMockData';
import LoaderSpinner from '@Shared/LoaderSpinner/LoaderSpinner';
import { motionGuidelines } from './motionGuideLinesData';

const MotionModalPage1 = ({ pageIndex, setDocumentName, setMotionId, user, caseId, motionType, citations, setCitations, setLoading, advancePage, onError, setCurrentApi }) => {
    const { setCurrentPageIndex, setFormData, formData, closeModal } = useContext(
        GenerateMotionContext
    );
    const { selectedMotion } = useContext(GenerateMotionContext);
    const [disabled, setDisabled] = useState(true);

    const MINIMUM_QUERY_LENGTH = 5;

    const handleChange = (e) => {
        setFormData({ ...formData, query: e.target.value });
        if (e.target.value.length < MINIMUM_QUERY_LENGTH) {
            setDisabled(true);
        } else {
            if (disabled) {
                setDisabled(false);
            }
        }
    };


    const handleMotionApiCall = async (apiFunction, params, onSuccess) => {
        try {
            advancePage(2);
            const response = await apiFunction(...params);
            if (response?.success) {
                onSuccess(response);
            } else {
                setLoading(false);
                onError(true);
                setCurrentPageIndex(0);
                setCurrentApi(() => () => handleMotionApiCall(apiFunction, params, onSuccess));
            }
        } catch (err) {
            console.log(err);
            setLoading(false);
            setCurrentApi(() => () => handleMotionApiCall(apiFunction, params, onSuccess));
        }
    };

    const handleOnClick = async () => {
        const userId = user.cognito_user_id;
        setLoading(true);

        if (md.use) {
            setDocumentName(md.documentName);
            setMotionId(md.motionId);
            setCitations(md.citations);
            setLoading(false);
            return;
        }

        // First call: generate document
        handleMotionApiCall(
            generateDocumentLambda,
            [userId, caseId, selectedMotion.value, formData.query],
            (generateDocumentLambdaResponse) => {
                const { motionId, documentName } = generateDocumentLambdaResponse.data;
                setDocumentName(documentName);
                setMotionId(motionId);

                // Second call: query precedents – now we pass appId as an extra parameter!
                handleMotionApiCall(
                    queryPrecedents,
                    [userId, caseId, documentName, motionId],
                    (queryPrecedentsResponse) => {
                        const { citations } = queryPrecedentsResponse.data;

                        // Third call: process precedents
                        handleMotionApiCall(
                            processPrecedents,
                            [userId, caseId, motionId, documentName, citations],
                            (processPrecedentsResponse) => {
                                const processedCitations =
                                    processPrecedentsResponse.data.processed_citations?.map(
                                        (recommendation, index) => ({
                                            ...recommendation,
                                            id: index,
                                        })
                                    );
                                setCitations(processedCitations);
                                setLoading(false);
                                setCurrentPageIndex(pageIndex + 1);
                            }
                        );
                    }
                );
            }
        );
    };
    return (
        <>
            <GenerateMotionModalPage
                className="motion-modal-page-1 p-4"
                title="Why are you filing this motion?"
                pageIndex={pageIndex}
            >
                <div>
                    <p className='motion-filing-guidelines-title'>To produce the highest quality motion, consider the following:</p>
                    <ul className='motion-filing-listing-section'>
                        {motionGuidelines.map((item, index) => (
                            <li key={index} className='motion-filing-listing'>
                                <span>{item.title}:</span> {item.description}
                            </li>
                        ))}
                    </ul>
                </div>
                <textarea
                    value={formData.query}
                    onChange={handleChange}
                    placeholder="Describe motion's purpose"
                ></textarea>
                <SharedButton
                    disabled={disabled}
                    onClick={handleOnClick}
                    className="button--primary"
                >
                    Continue
                </SharedButton>
            </GenerateMotionModalPage>
        </>
    );
};

export default MotionModalPage1;
