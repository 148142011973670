import React, { useContext, useEffect } from 'react';
import backIcon from '@icons/exit-arrow-top.svg';
import './Header.css';
import ChatContext from '../../ChatContext';
import { getCase } from '@lib/apis/resources/case';
import { useParams, useNavigate } from 'react-router-dom';
import { getThread } from '@lib/apis/resources/thread';
import { useSelector } from 'react-redux';
import { getConversations } from '@lib/apis/chatbot';

const Header = () => {
  const { currentCase, setCurrentCase, selectedCaseId, currentThread, setCurrentThread, setConversations, conversationId, isNewConversations } = useContext(ChatContext);
  const user = useSelector(state => state.user);
  const auth = useSelector(state => state.auth);
  const navigate = useNavigate();

  useEffect(() => {

    const fetchCase = async () => {
      const response = await getCase(selectedCaseId);
      setCurrentCase(response?.data?.case);
    }
    const fetchThread = async () => {
      const response = await getThread(user.cognito_user_id, selectedCaseId);
      const newThread = response?.data?.caseThreadByThreadName;
      setCurrentThread(newThread);
    }
    const fetchConversations = async () => {
      const response = await getConversations(auth?.chatToken);
      const convos = response?.data?.conversations;
      setConversations(convos);
    }
    if (selectedCaseId) {
      fetchCase();
      fetchThread();
      fetchConversations();
    } else {
      setCurrentCase(null);
      setCurrentThread(null);
      setConversations(null);
    }
  }, [selectedCaseId, isNewConversations]);

  return (
    <div className="chat-welcome-header-container">
      <h3>Fedcrim.ai v 0.1 {currentCase && ` - Case: ${currentCase?.caseName}`}</h3>
      <img src={backIcon} alt="" />
    </div>
  );
};

export default Header;
