import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import ButtonPrimary from '@Shared/ButtonPrimary/ButtonPrimary';
import JuristLogo from '@assets/images/juristAi/jurist-logo.png';
import AtticusLogo from '@assets/images/atticus/logo/atticus-full-logo.svg';
import { ReactComponent as HomeIcon } from '@icons/home.svg';
import { ReactComponent as TeamLargeIcon } from '@icons/team-large.svg';
import { ReactComponent as CasesIcon } from '@icons/folder-open.svg';
import { ReactComponent as AccountIcon } from '@icons/profile.svg';
import { ReactComponent as SubscriptionsIcon } from '@icons/cash.svg';
import { ReactComponent as OrganizationIcon } from '@icons/team-small.svg';
import { ReactComponent as AskIcon } from '@icons/chat-bubble.svg';
import NavBarListItem from '@Shared/Layout/NavBarListItem/NavBarListItem';
import './NavBar.css';
import { ReactComponent as CaseOverviewIcon } from '@icons/caseOverview.svg';
import { ReactComponent as CaseTimelineIcon } from '@icons/caseTimeline.svg';
import { ReactComponent as UploadDocumentIcon } from '@icons/uploadDocument.svg';
import NavLogo from '@Shared/NavLogo/NavLogo';


const JURISTAI_BASE_URL = process.env.REACT_APP_JURIST_BASE_URL;

// TODO: Fix OrganizationIcon's bold stroke issue

const NavBar = ({ selectedCase }) => {
  const { id } = useParams();
  return (
    <div className="dashboard-navigation">
      <div className="dashboard-navigation-logo-container">

        <NavLogo />
      </div>
      <ul className="dashboard-navigation-list-items">
        <NavBarListItem icon={HomeIcon} text="Home" url="/dashboard" />
        <NavBarListItem icon={TeamLargeIcon} text="Your Legal Team" url="/dashboard/team" />
        <NavBarListItem icon={CasesIcon} text="Cases" url="/dashboard/cases/" />
        <NavBarListItem icon={AccountIcon} text="Account Options" url="/dashboard/account/" />
        <li className="dashboard-navigation-special-link">
          <Link to="#">Select practice area</Link>
        </li>
        <NavBarListItem icon={SubscriptionsIcon} text="Manage Subscriptions" url="/dashboard/subscriptions/" />
        <NavBarListItem icon={OrganizationIcon} text="Manage Organization" url={`${JURISTAI_BASE_URL}/dashboard/organization/`} target="_blank" />
        {selectedCase && (
          <>
            <h6 className="navigation-case-title">{selectedCase?.caseDetails?.caseName}</h6>
            <ul className="dashboard-navigation-list-items">
              <NavBarListItem icon={CaseOverviewIcon} text="Case Overview" url={`/dashboard/cases/${id}/overview`} />
              <NavBarListItem icon={CaseTimelineIcon} text="Case Timeline" url={`/dashboard/cases/${id}/timeline`} />
              <NavBarListItem icon={UploadDocumentIcon} text="Upload Documents" url={`/dashboard/cases/${id}/upload`} />
            </ul>
          </>
        )}
      </ul>
      {id && (
        <Link to={`/chat/case/${id}`}>
          <ButtonPrimary style={{ width: '90%', padding: '10px', fontSize: '1rem', textAlign: 'left' }}>
            <AskIcon style={{ width: '2rem' }} />
            Ask Fedcrim.ai About This Case
          </ButtonPrimary>
        </Link>
      )}

    </div>
  );
};

export default NavBar;
