import React from 'react'
import { Link } from 'react-router-dom';
import Logo from '@assets/images/juristAi/jurist-logo-alone.png';
import AtticusLogo from '@assets/images/atticus/logo/atticus-logo.png';
import './NavLogo.css'

function NavLogo() {
    return (
        <div className="nav-logo-container">
            <Link to={'https://juristai.org/ '} className="logo">
                <img src={Logo} alt="JuristAI" />
            </Link>
            <Link to={'/dashboard'} className="logo">
                <img src={AtticusLogo} alt="JuristAI" />
                <div className="logo-head">
                    <h4>Fedcrim AI
                        <span>Generate AI Law Briefs</span>

                    </h4>
                </div>
            </Link>
        </div>
    )
}


export default NavLogo
