import { chatbotPromptsMap } from '@lib/utils/chatbot';

export const argumentData = [
  {
    id: 1,
    title: 'Support a claim',
    description: 'Develop arguments to support your position with relevant evidence and logic.',
    question:"What prosecutorial argument are you seeking to rebut?",
    flow: chatbotPromptsMap.argumentFlow,
  },
  {
    id: 2,
    title: 'Refute the opposing side',
    description: "Build counterarguments to challenge the opposing side's claims and expose weaknesses.",
    question:"What prosecutorial argument are you seeking to Refute?",
    flow: chatbotPromptsMap.argumentClaimFlow,
  },
  {
    id: 3,
    title: 'Other',
    question: "What other arguments or points would you like to discuss?",
    flow: chatbotPromptsMap.argumentRefuteFlow,
  },
 
];

// argumentData.js

export const argumentGeneratedData = [
    {
        heading: "Understood. You're looking to challenge the prosecution's claim that the fingerprints at the scene directly link the defendant to the crime.",
        sections: [
            {
                title: "Step 1: Initial Counterargument",
                text: "One potential counterargument is that fingerprints alone do not indicate when or how the defendant was present at the scene. The prosecution must also establish the time frame and context in which those fingerprints were left."
            },
            {
                title: "Step 2: Additional Support",
                text: "You could also argue that there are innocent explanations for the presence of the fingerprints, such as the defendant visiting the location before the crime took place or being in contact with the area for unrelated reasons."
          },

        ]
    }
];
