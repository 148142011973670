export const extractBucketName = (response) => {
  const regex = /S3 bucket '([a-z0-9-]+)'/i;
  const match = response.match(regex);

  if (match && match[1]) {
    return match[1];
  } else {
    return null; // If no match, return null
  }
}

export const getAppId = () => {
  if (process.env.REACT_APP_APP_ID) {
    return process.env.REACT_APP_APP_ID;
  } else {
    return 1;
  }
};

export const sortTimelineData = (data, filterType) => {
  const sortedData = [...data];
  if (filterType === 'Oldest to Newest') {
    sortedData?.sort((a, b) => new Date(a.dateFiled) - new Date(b.dateFiled));
  } else if (filterType === 'Newest to Oldest') {
    sortedData?.sort((a, b) => new Date(b.dateFiled) - new Date(a.dateFiled));
  }
  return sortedData;
};

export const truncate = (str, length = 250, ending = '...') =>{
  if (str.length <= length) {
      return str; 
  }
  
  return str.substring(0, length) + ending;
}

export const parseCitation = (citation) => {
  const regex = /<a href=['"]([^'"]+)['"][^>]*>([^<]+)<\/a>/;
  const citationData = citation?.match(regex);

  if (citationData) {
    const citationURL = citationData[1];
    const citationTitle = citationData[2];
    return <a href={citationURL} target="_blank">{citationTitle}</a>;
  }
  return citation;
};