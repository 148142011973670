import React, { useContext, useEffect, useRef } from 'react';
import './Research.css';
import Header from '../Shared/Header/Header';
import Message from './Message/Message';
import ChatContext from '../ChatContext';
import ChatInputBox from '../Shared/ChatInputBox/ChatInputBox';
import ProcessingMessageBubble from './ProcessingMessageBubble/ProcessingMessageBubble';
import { useLocation } from 'react-router-dom';

// TODO: Extract these functions to a utility file
const prettyDate = (dateString) => {
  const date = new Date(dateString);
  const options = { year: 'numeric', month: 'short', day: '2-digit' };
  return date.toLocaleDateString('en-US', options);
}
const Research = () => {
  const { waitingForResponse, setWaitingForResponse, messages, setMessages, addMessage, setConversationId } =
    useContext(ChatContext);

  const location = useLocation();
  const researchFlow = location.state;
  const chatContainerRef = useRef(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages, waitingForResponse]);

  useEffect(() => {
    setConversationId(null);
    setMessages([]);
  }
  , [])

  return (
    <section className="chat-research-section">
      <Header />
      <div className="chat-research-container">
        <div className="chat-research-content" ref={chatContainerRef}>
          <p className="chat-research-date">{ messages[0]?.dateCreated ? prettyDate(messages[0]?.dateCreated) : 'New chat' }</p>
          {messages.map((message) => (
            <Message
              key={message.id}
              text={message?.text ? message.text : message?.content?.[0]?.text?.value ? message.content[0].text.value : ""}
              userIsAuthor={message.sender === "User" || message.userIsAuthor}
            />
          ))}
        </div>
        {waitingForResponse && <ProcessingMessageBubble />}
        <ChatInputBox promptFlow={researchFlow} />
      </div>
      {/* Add Footer Here if needed */}
    </section>
  );
};

export default Research;
