import { chatbotPromptsMap } from '@lib/utils/chatbot';

export const trialRolesData = [
  {
    id: 1,
    title: "Craft Interrogatories",
    description:
      "Develop targeted questions to gather information or clarify key points from the opposing party or witnesses.",
    flow: chatbotPromptsMap.trialInterrogateFlow,
  },
  {
    id: 2,
    title: "Develop Trail Strategies",
    description:
      "Outline your approach for presenting evidence, qeustioning witnesses, and structuring your overall case for trial.",
    flow: chatbotPromptsMap.trialStrategiesFlow,
  },
  {
    id: 3,
    title: "Prepare Witnesses",
    description:
      "Assist in preparing witnesses for testimony, ensuring they're ready to answer questions and provide clear, convincing statements.",
    flow: chatbotPromptsMap.trialWitnessFlow,
  },
  {
    id: 4,
    title: "Other",
    description:
      "I need assist with the different aspects of the trial, such as researching case law, drafting motions, addressing procedural issues, or any other task.",
    flow: chatbotPromptsMap.trialFlow,
  },
];

export const trialGeneratedData = [
  {
    title: "",
    points: [
      {
        point: "Good Faith Belief:",
        description:
          "Show that the defendant had a genuine, though perhaps misguided, belief that they were acting within their legal rights. Mistake of law or fact may mitigate the defendant's liability by showing there was no criminal intent.",
      },
    ],
  },
  {
    title: "Closing Argument Focus",
    points: [
      {
        point: "Reiterate Lack of Intent:",
        description:
          "Focus the closing argument on the lack of intent, underscoring that without criminal intent, the defendant cannot be found guilty of election interference.",
      },
      {
        point: "Highlight Reasonable Doubt:",
        description:
          "Emphasize any contradictions, procedural flaws, or unclear aspects of the prosecution's case. Ensure the jury understands that reasonable doubt applies and that any uncertainty must lead to acquittal.",
      },
    ],
  },
  {
    title: "Sentencing Strategy (If Convicted)",
    points: [
      {
        point: "Argue for Leniency:",
        description:
          "Should the defendant be convicted, argue that their actions did not warrant severe punishment based on mitigating factors like lack of prior criminal history, lack of substantial harm, or cooperation during the investigation.",
      },
    ],
  },
  {
    messages: [
      {
        message:
          "Developing a trail strategy for a federal election interference case requires a  well-rounded approach, focusing on multiple angles to challenge the prosecution's narrative and introduce reasonable doubt. Below are the key components of a trail strategy tailored for defending a federal election interference case in Washington, D.C.:",
      },
      {
        message:
          "This strategy should provide a robust defense, focusing on undermining the prosecution's narrative while presenting the defendant's actions in a lawful and understandable light.",
      },
    ],
  },
];
