import React, { useState, useEffect } from 'react';
import PageTitle from '../../Shared/PageTitle/PageTitle';
import MyCasesTable from './MyCasesTable/MyCasesTable';
import { useOutletContext } from 'react-router-dom';
import SearchSection from '../../Atticus/SearchSection/SearchSection';
import './CasesIndex.css';
import { advancedSearch } from '@lib/apis/pacer-api';
import casesData from '@lib/mocks/cases';
import { getCasesForUser } from '@lib/apis/resources/user';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const CasesIndex = () => {
  const { setSelectedCase } = useOutletContext();
  const [cases, setCases] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  const fetchCasesData = async () => {
    setIsLoading(true);
    try {
      const response = await getCasesForUser(user?.email);
      if (response?.success === true) {
        setCases(response.data);
      } else {
        console.log('No cases found for the user.');
      }
    } catch (error) {
      console.error('Error fetching cases:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCasesData();
  }, [navigate]);

  const handleCaseSelect = (selectedCase) => {
    setSelectedCase(selectedCase);
  };
  return (
    <main className="dashboard-page cases-page">
      <PageTitle title="Federal Criminal" subtitle="Cases" />
      <SearchSection />
      <MyCasesTable cases={cases}  onSelectCase={handleCaseSelect} isLoading={isLoading} onReload={()=> fetchCasesData()}  />
    </main>
  );
};

export default CasesIndex;
