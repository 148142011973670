import React, { useState, useContext, useRef } from 'react';
import GenerateMotionModalPage from './GenerateMotionModalPage';
import GenerateMotionContext from '../../GenerateMotionContext';
import Button from '@Shared/Button/Button';
import { ReactComponent as DownloadIcon } from '@icons/download-file.svg';
import { getFileUrl } from '@lib/utils/awsSdkHelper';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Make sure this CSS is imported (where .motion-download-disabled is defined):
import './MotionModalPages.css';

const MotionModalPage4 = ({
                              pageIndex,
                              documentName,
                              caseId,
                              downloadUrl,    // The final S3 link if already set
                              setDownloadUrl  // A setter if you want to store the link in parent
                          }) => {
    // const fixUrl = (url) => {
    //     try {
    //         let decodedUrl = decodeURIComponent(url);
    //         const s3BaseUrlRegex = /https:\/\/[\w.-]+\.s3[\w.-]*\.amazonaws\.com/;
    //         const match = decodedUrl.match(s3BaseUrlRegex);
    //         if (!match) {
    //             throw new Error("Valid S3 base URL not found in the malformed URL.");
    //         }
    //         const baseUrl = match[0];
    //         const cleanedUrl = decodedUrl.substring(decodedUrl.lastIndexOf(baseUrl));
    //         const fixedUrl = decodeURIComponent(cleanedUrl);
    //         return fixedUrl;
    //     } catch (error) {
    //         console.error("Failed to fix the URL:", error.message);
    //         return null;
    //     }
    // }

    // const fetchPresignedUrl = async () => {
    //   try {
    //     const url = await generatePresignedURL(key, caseId);
    //     setDownloadUrl(url);
    //     return url;
    //   } catch (error) {
    //     console.log("Error fetching presigned URL:", error);
    //   }
    // };

    // const downloadMotionTemp = async () => {
    //   const downloadMotionUrl = await fetchPresignedUrl();
    //   if (downloadMotionUrl) {
    //     console.log("Downloading motion from URL:", downloadMotionUrl);
    //     const url = window.URL.createObjectURL(downloadMotionUrl);

    //     window.location.href = downloadUrl;
    //   } else {
    //     console.error("Download URL not available.");
    //   }
    // };
    const { selectedMotion } = useContext(GenerateMotionContext);
    const user = useSelector((state) => state.user);

    // Tracks whether the user has checked the consent box
    const [checked, setChecked] = useState(false);

    // If you still want to reference the link
    const blobButtonRef = useRef(null);

    // Only fetch the doc link after the user checks
    const handleCheckChanged = async (e) => {
        const isNowChecked = e.target.checked;
        setChecked(isNowChecked);

        if (isNowChecked) {
            // fetch the doc link now, so the user can download
            const key = `${documentName}_clean.docx`;
            console.log('Fetching doc from S3:', key, user.cognito_user_id, caseId);

            const downloadMotionUrl = await getFileUrl(key, user.cognito_user_id, caseId);
            if (downloadMotionUrl) {
                console.log('Got doc URL =>', downloadMotionUrl);
                setDownloadUrl(downloadMotionUrl);
            } else {
                console.error('Download URL not available.');
            }
        } else {
            // If they uncheck, remove or reset the link
            setDownloadUrl('');
        }
    };

    return (
        <GenerateMotionModalPage title="Fedcrim.ai is done!" pageIndex={pageIndex} className="p-4">
            <p>
                Your motion has been successfully generated. Please review this draft{' '}
                {selectedMotion?.name}.
            </p>
            <p>
                Note that the following does not constitute legal advice, and it is the responsibility of the attorney representing the client to review all generated
                content thoroughly.
            </p>
            <p>Confirm to Get Motion</p>

            <div className="download-motion-confirm-container">
                <form>
                    <input
                        name="checked"
                        type="checkbox"
                        id="download-motion-agree-checked"
                        className='download-motion-checkbox'
                        checked={checked}
                        onChange={handleCheckChanged}
                    />
                    <label htmlFor="download-motion-agree-checked">
                        I confirm that I have reviewed the draft and understand that the onus of responsibility for reviewing all generated content is upon the individual
                        attorney representing a client.
                    </label>
                </form>

                {/* If not checked, disable the button and apply "motion-download-disabled" */}
                {!checked ? (
                    <Button className="button--outline motion-download-disabled" disabled>
                        <DownloadIcon />
                        {selectedMotion?.name}
                    </Button>
                ) : (
                    // Once checked = true, we have a real URL
                    <Link to={downloadUrl || '#'} ref={blobButtonRef} download>
                        <Button className="button--outline download-motion-button">
                            <DownloadIcon />
                            {selectedMotion?.name}
                        </Button>
                    </Link>
                )}
            </div>
        </GenerateMotionModalPage>
    );
};

export default MotionModalPage4;
