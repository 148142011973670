export const motionGuidelines = [
    {
      title: "Define Your Legal Strategy Clearly",
      description:
        "Specify whether the motion should take an oppositional, conciliatory, procedural, or substantive approach. This helps shape the tone and arguments effectively."
    },
    {
      title: "Prioritize Key Arguments",
      description:
        "Identify the most critical legal points to emphasize. If multiple defenses apply, indicate their ranking in importance."
    },
    {
      title: "Anticipate and Address Counterarguments",
      description:
        "If you foresee potential opposing arguments, provide insights or rebuttals to fortify your position."
    },
    {
      title: "Tailor for Court-Specific Preferences",
      description:
        "If the motion is being submitted to a judge with known preferences, request alignment with their reasoning style or past rulings."
    },
    {
      title: "Clarify Relief Sought",
      description:
        "Clearly define the specific relief or outcome the motion seeks, ensuring precision in the request."
    }
  ];
  