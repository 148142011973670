/* eslint-disable */
export const getParamsFromConversationId = (conversationId) => {
  const result = {};
  const pairs = conversationId.split('|');

  pairs.forEach(pair => {
    const [key, value] = pair.split(':');
    result[key] = value;
  });

  return result;
}

export const filterMessagesForTag = (messages, tag) => {
  return messages.filter(message => message.tag === tag);
}



export const getTagFromLocation = () => {
  const TAG_OPTIONS = [
    "research",
    "analyze",
    "argument",
    "compare",
    "profile",
    "trial",
  ];
  const locationArray = location.pathname.split("/");
  const tag = locationArray[locationArray.length - 1];
  return TAG_OPTIONS.includes(tag) ? tag : "research";
}


export const chatbotPromptsMap = {
  researchFlow: process.env.REACT_APP_RESEARCH_WORKFLOW,
  compareFlow: process.env.REACT_APP_COMPARE_WORKFLOW,
  analyzeFlow: process.env.REACT_APP_ANALYZE_WORKFLOW,
  profileFlow: process.env.REACT_APP_PROFILE_WORKFLOW,
  profileDefendantFlow: process.env.REACT_APP_PROFILE_DEFENDANT,
  profileWitnessFlow: process.env.REACT_APP_PROFILE_WITNESS,
  profileExpertFlow: process.env.REACT_APP_PROFILE_EXPERT,
  profileProsecutorFlow: process.env.REACT_APP_PROFILE_PROSECUTOR,
  profileAttorneyFlow: process.env.REACT_APP_PROFILE_ATTORNEY,
  argumentFlow: process.env.REACT_APP_ARGUMENT_WORKFLOW,
  argumentClaimFlow: process.env.REACT_APP_ARGUMENT_CLAIM,
  argumentRefuteFlow: process.env.REACT_APP_ARGUMENT_REFUTE,
  trialFlow: process.env.REACT_APP_TRIAL_WORKFLOW,
  trialInterrogateFlow: process.env.REACT_APP_TRIAL_INTERROGATE,
  trialStrategiesFlow: process.env.REACT_APP_TRIAL_STRATEGIES,
  trialWitnessFlow: process.env.REACT_APP_TRIAL_WITNESS
};

export const formatChatGPTResponse = (response) => {
  let formattedText = response.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  formattedText = formattedText.replace(/(?:\n|^)(\d+)\. (.+)/g, '<ol><li>$2</li></ol>');
  formattedText = formattedText.replace(/(?:\n|^)[\-\*] (.+)/g, '<ul><li>$1</li></ul>');
  formattedText = formattedText
      .split(/\n+/) 
      .map(line => line.trim())
      .filter(line => line.length > 0) 
      .map(line => `<p>${line}</p>`)
      .join('');

  formattedText = formattedText.replace(/<\/ul><ul>/g, '').replace(/<\/ol><ol>/g, '');

  return <div dangerouslySetInnerHTML={{ __html: formattedText }} />;
}
export const formatChatResponse = (response) => {
  if (!response) return "";

  response = response?.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");
  response = response?.replace(/(\d+\.)\s/g, "<br><br>&nbsp;$1 ");
  response = response?.replace(/#/g, "");
  response = response?.replace(/-\s(.*?):/g, function (match, p1) {
    return `<br>&nbsp;&nbsp;&nbsp;&nbsp;• ${p1}:`;
  });

  return response?.trim();
}

export const STANDARD_ERROR_MESSAGE = {
  "messageId": "00000000-0000-0000-0000-000000000000",
  "conversationId": "00000000-0000-0000-0000-000000000000",
  "parentMessageId": "00000000-0000-0000-0000-000000000000",
  "isCreatedByUser": false,
  "model": "gpt-4",
  "sender": "GPT-4",
  "text": "There was an error with your request. Please log out and log back in",
  "promptTokens": 13,
  "endpoint": "openAI",
  "finish_reason": "stop"
}
