import React, { useEffect, useRef, useContext, useState } from 'react';
import './ChatInputBox.css';
import attachmentIcon from '@icons/attachment-icon.svg';
import sendIcon from '@icons/send-icon.svg';
import ChatContext from '../../ChatContext';
import { argumentGeneratedData } from '../../Workflow/Argument/aurgumentData';
import { sendMessage } from '@lib/apis/chatbot';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { STANDARD_ERROR_MESSAGE, getTagFromLocation } from '@lib/utils/chatbot';


const ChatInputBox = ({ type, isDefaultSelected = false, promptFlow, noRedirect=false }) => {
  const { waitingForResponse, setWaitingForResponse, addRequestMessage, addResponseMessage, currentThread,
    parentMessageId,
    setParentMessageId,
    conversationId,
    setConversationId,
    setIsNewConversations,
    setLastParams,
  } = useContext(ChatContext);

  const textareaRef = useRef(null);
  const fileInputRef = useRef(null);
  const [text, setText] = useState('');
  const [file, setFile] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useSelector(state => state.auth);
  const { caseId } = useParams();
  const user = useSelector(state => state.user);

  const handleAttachmentClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // If the user is chatting about a case, redirect to the case chat, otherwise redirect to the global chat
  const redirectIfNeeded = () => {
    if (noRedirect) return;
    const currentPath = location.pathname;
    let expectedPath = caseId ? `/chat/case/${caseId}/research` : '/chat/research';
    if (currentPath !== expectedPath) {
      navigate(expectedPath);  // Redirect to the login page if not on the dashboard
    }
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log('Selected file:', file);
    }
  };

  const handleSubmit = async () => {
    redirectIfNeeded();
    let requestMessage = {}
    // Get the text from the textarea
    requestMessage.text = textareaRef.current.value;
    if (!requestMessage.text.trim()) return;

    // Send request to the server
    setWaitingForResponse(true);
    addRequestMessage(requestMessage, type ?? '', isDefaultSelected);
    textareaRef.current.value = '';
    setText('');
    autoResizeTextarea();

    const params = {
      input: `${promptFlow ? promptFlow : ""} | ${text}`,
      threadId: currentThread?.threadId,
      conversationId: conversationId || null,
      parentMessageId: parentMessageId || null,
      userId: user.cognito_user_id,
      caseId: caseId,
      tag: getTagFromLocation(location),
    }

    let response;

    try {
      response = await sendMessage(params, auth.chatToken);
    } catch (error) {
      console.log('errsendMessage error', error);
      addResponseMessage(STANDARD_ERROR_MESSAGE, type ?? '', isDefaultSelected);
    }
  
    // Return response from the server
    setWaitingForResponse(false);


    // If the response is successful, add the response message to the chat
    if (response.success) {
      const returnedMessage = response.data;
      addResponseMessage(returnedMessage, type ?? '', isDefaultSelected);
      setParentMessageId(returnedMessage.messageId);
      setConversationId(returnedMessage.conversationId);
      setIsNewConversations(false)

      setLastParams(params);
    } else {
      addResponseMessage(STANDARD_ERROR_MESSAGE, type ?? '', isDefaultSelected);
    }
  };

  const autoResizeTextarea = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = '40px';
      const newHeight = textarea.scrollHeight;

      if (newHeight > 300) {
        textarea.style.height = '300px';
        textarea.style.overflowY = 'scroll';
      } else if (textarea.value.length === 0) {
        textarea.style.height = '40px';
      } else {
        textarea.style.height = `${newHeight}px`;
        textarea.style.overflowY = 'hidden';
      }
    }
  };

  const handleTextareaChange = (event) => {
    const value = event.target.value;
    setText(value);
    autoResizeTextarea();
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && text.trim()) {
      event.preventDefault();
      handleSubmit();
    }
  };

  useEffect(() => {
    autoResizeTextarea();
  }, []);

  return (
    <div className="chat-input-box">
      <div className="chat-input-container">
        <div className="chat-attachment-icon-container" onClick={handleAttachmentClick}>
          <img src={attachmentIcon} alt="Attachment" className="attachment-icon" />
        </div>
        <div className="chat-message-text">
          <textarea
            ref={textareaRef}
            className="chat-message-input"
            placeholder="Message Fedcrim.ai v0.1"
            onInput={handleTextareaChange}
            onKeyDown={handleKeyDown}
            rows={1}
          />
        </div>
      </div>
      <div className="send-message-button" onClick={handleSubmit}>
        <img src={sendIcon} alt="Send" className="send-message-icon cursor-pointer" />
      </div>
      <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
    </div>
  );
};

export default ChatInputBox;
