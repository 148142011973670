import React, { useContext, useState } from 'react';
import GenerateMotionModalPage from './GenerateMotionModalPage';
import SharedButton from '@Shared/Button/Button';
import CaseRecommendationPannel from '../CaseRecommendationPannel';
import GenerateMotionContext from '../../GenerateMotionContext';
import { hallucinationDetection } from '@lib/apis/pacer-api';
import md from '@lib/mocks/motionGenerationMockData';
import LoaderSpinner from '@Shared/LoaderSpinner/LoaderSpinner'; // <--- import the spinner

const MotionModalPage2 = ({
                            handleClose,
                            pageIndex,
                            advancePage,
                            citations,
                            user,
                            caseId,
                            motionId,
                            documentName,
                            setDownloadUrl,
                            setLoading
                          }) => {
  const { setCurrentPageIndex } = useContext(GenerateMotionContext);

  // local state that toggles the spinner (rather than showing the button)
  const [localLoading, setLocalLoading] = useState(false);

  // If you’re using the mock data
  const mockApiCall = async () => {
    console.log("Getting hallucinationDetection api response with mock data", md);
    const response = await hallucinationDetection(
        md.userId,
        md.caseId,
        md.motionId,
        md.documentName,
        md.citations
    );
    console.log("response from hallucinationDetection: ", response);
    setDownloadUrl(response.data.download_url);
  };

  const handleOnClick = async () => {
    // Turn on local loading (for the spinner) and the parent setLoading
    setLocalLoading(true);
    setLoading(true);

    if (md.use) {
      await mockApiCall();
    } else {
      console.log(
          "calling hallucinationDetection with arguments: ",
          user.cognito_user_id,
          caseId,
          motionId,
          documentName,
          citations
      );
      const response = await hallucinationDetection(
          user.cognito_user_id,
          caseId,
          motionId,
          documentName,
          citations
      );
      console.log("-------response from hallucinationDetection: --------", response);
      if (response.success) {
        setDownloadUrl(response.data.download_url);
      }
    }

    // After the API call, we wait 4 seconds, then turn off loading + move to page3.
    setTimeout(() => {
      setLocalLoading(false);
      setLoading(false);
      setCurrentPageIndex(3);
    }, 4000);
  };

  return (
      <GenerateMotionModalPage
          title="Case Recommendations"
          handleClose={handleClose}
          pageIndex={pageIndex}
          className="case-recommendation-content-container p-4"
      >
        {citations?.map((recommendation, index) => (
            <CaseRecommendationPannel
                key={index}
                name={recommendation.citation}
                description={recommendation.summary}
                caseRecommendation={recommendation}
            />
        ))}

        {/* If localLoading is true, show the spinner; else show the "Continue" button */}
        {localLoading ? (
            <LoaderSpinner />
        ) : (
            <SharedButton
                className="button--primary motion-advance-button"
                onClick={handleOnClick}
            >
              Continue
            </SharedButton>
        )}
      </GenerateMotionModalPage>
  );
};

export default MotionModalPage2;
