import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import TimelineItem from './TimelineItem/TimelineItem';
import './CaseTimeline.css';
import Dropdown from '@Shared/Dropdown/Dropdown';
import arrowUp from '@icons/arrow-up-filled-black.svg';
import arrowDown from '@icons/arrow-down-filled-black.svg';
import cases from '@lib/mocks/cases';
import { useOutletContext } from 'react-router-dom';
import Loader from '@Shared/Loader/Loader';
import { getDocketEntriesForCase } from '@lib/apis/resources/case';
import { sortTimelineData } from '@lib/utils/general';
import searchIcon from '@icons/search-gray-icon.svg';
import Button from '@Shared/Button/Button';
import { retrieveCaseTimeline } from '@lib/apis/pacer-api';
import { docketClassification } from '@lib/apis/pacer-api';
import { useSelector } from 'react-redux';
import { formatMMDDYYYY } from '@lib/utils/dateFormatHelpers';

const FILTER_TYPES = ['Newest to Oldest', 'Oldest to Newest'];

const CaseTimeline = () => {
  const { id: caseId } = useParams();
  const user = useSelector((state) => state.user);
  const { cognito_user_id: userId } = user;
  const [selectedFilter, setSelectedFilter] = useState(FILTER_TYPES[0]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [timeLineData, setTimeLineData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [visibleCount, setVisibleCount] = useState(3);
  const { selectedCase } = useOutletContext();

  const dashboardHeaderId = '#atticus-dashboard-header';
  const dropdownTimeLineId = '#case-time-line-dropdown-id';

  const dashboardHeaderHeight = document.querySelector(dashboardHeaderId)?.clientHeight;
  const dropdownTimeLineHeight = document.querySelector(dropdownTimeLineId)?.clientHeight;

  const fetchTimeLineData = async () => {

    try {
      const response = await getDocketEntriesForCase(caseId);
      if (response?.success == true && response?.data !== null) {
        const sortedData = sortTimelineData(response?.data, selectedFilter);
        setTimeLineData(sortedData);
        setFilteredData(sortedData);
        setLoading(false);
      }
    } catch (error) {
      console.log("CaseTimeLineError: ", error)
    } finally {
      console.log("done")
    }
  };

  useEffect(() => {

    fetchTimeLineData();
  }, [caseId]);



  useEffect(() => {
    const filtered = timeLineData?.filter((item) =>
      item?.classification?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item?.docketEntryId?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
      item?.description?.toLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
      item?.entryNumber?.toString().toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filtered);
  }, [searchTerm, timeLineData]);

  const handleFunctionSelect = (filterType) => {
    setSelectedFilter(filterType);
    setCurrentIndex(0);

    const sortedData = sortTimelineData(timeLineData, filterType);
    setTimeLineData(sortedData);
    setFilteredData(sortedData);
  };

  useEffect(() => {
    const calculateVisibleItems = () => {
      const screenHeight = window.innerHeight;
      const availableHeight = screenHeight - dashboardHeaderHeight - dropdownTimeLineHeight;
      const timeLineItems = document.querySelectorAll('.timeline-item');

      let totalHeight = 0;
      timeLineItems.forEach((item) => {
        totalHeight += item.clientHeight;
      });

      const averageHeight = timeLineItems.length > 0 ? totalHeight / timeLineItems.length + 30 : 0;

      const newVisibleCount = Math.max(Math.floor(availableHeight / averageHeight), 3);
      setVisibleCount(newVisibleCount || 3);

    };

    calculateVisibleItems();
    window.addEventListener('resize', calculateVisibleItems);

    return () => window.removeEventListener('resize', calculateVisibleItems);
  }, [filteredData]);

  const handleNext = () => {
    if (currentIndex + visibleCount < filteredData?.length) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleRefresh = async () => {
    setLoading(true)
    try {
      const result = await retrieveCaseTimeline(caseId);
      if (result) {
        const transformedDocuments = Object.values(result?.data)
          .map((entry) => {
            return entry?.docket_entry?.recap_documents?.map((recapDoc) => ({
              date: formatMMDDYYYY(entry.dateFiled),
              entered: formatMMDDYYYY(entry.dateEntered),
              dateFiled: entry.dateFiled,
              filing: entry?.description,
              url: entry?.resourceUri,
              title: recapDoc?.description,
            }));
          })
          .flat();
        const latestTransformedDocuments = transformedDocuments?.reverse();
        setDocketList(latestTransformedDocuments);
        setDocketData(latestTransformedDocuments);
      } else {
        console.log('Error in result data:', result?.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      // setLoading(false);
      console.log("")
    }


    await handleDocketClassApi();
    await fetchTimeLineData();
    setLoading(false)
  }

  const handleDocketClassApi = async () => {
    try {
      const result = await docketClassification(caseId, userId);
      if (result) {
        const transformedDocuments = Object.values(result?.data)
          .map((entry) => {
            return entry?.docket_entry?.recap_documents?.map((recapDoc) => ({
              date: formatMMDDYYYY(entry.dateFiled),
              entered: formatMMDDYYYY(entry.dateEntered),
              dateFiled: entry.dateFiled,
              filing: entry?.description,
              url: entry?.resourceUri,
              title: recapDoc?.description,
            }));
          })
          .flat();

      } else {
        console.log('Error in result data:', result?.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }
  return (
    <main className="case-timeline-page">
      <div className="case-timeline-page-dropdown" id='case-time-line-dropdown-id'>
        <div className="case-timeline-page-dropdown-content">
          <Button className="button--primary retry-atticus-timeline" onClick={handleRefresh}>
            Refresh
          </Button>
          <div className="search-input-container">
            <img src={searchIcon} alt="Search Icon" className="search-icon" />
            <input
              type="text"
              placeholder="Search..."
              className="search-input"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <Dropdown
            options={FILTER_TYPES}
            selectedOption={selectedFilter}
            onOptionSelect={handleFunctionSelect}
            placeholder="Select Function"
            isFilter
          />
        </div>
      </div>
      {loading ? <Loader /> :
        (
          <div className="timeline-container">
            {filteredData?.length > 0 ? (
              <>
                <img
                  src={arrowUp}
                  alt="Arrow Up"
                  className="case-timeline-filled-arrow-up"
                  onClick={handlePrevious}
                  style={{
                    opacity: currentIndex > 0 ? 1 : 0.5,
                    cursor: currentIndex > 0 ? 'pointer' : 'not-allowed',
                  }}
                />
                <div className="timeline-line"></div>
                <div className="timeline-items">
                  {filteredData
                    ?.slice(currentIndex, currentIndex + visibleCount)
                    ?.map((item, index) => (
                      <TimelineItem
                        key={index + currentIndex}
                        date={item?.dateFiled}
                        role={item?.classification}
                        documents={item?.description}
                        documentExists={item?.documentExists}
                        summaryS3Url={item.summaryS3Url}
                        docketEntryId={item.docketEntryId}
                        caseId={item.caseId}
                      />
                    ))}
                </div>
                <img
                  src={arrowDown}
                  alt="Arrow Down"
                  className="case-timeline-filled-arrow-down"
                  onClick={handleNext}
                  style={{
                    opacity: currentIndex < filteredData?.length - visibleCount ? 1 : 0.5,
                    cursor:
                      currentIndex < filteredData?.length - visibleCount
                        ? 'pointer'
                        : 'not-allowed',
                  }}
                />
              </>
            ) : (
              <div className="no-data-message">No timeline data available.</div>
            )}
          </div>
        )}
    </main>
  );
};

export default CaseTimeline;
