import React, { useState } from 'react';
import AtticusAiLogo from '@assets/images/atticus/logo/atticus-full-logo.svg';
import TextField from '@Shared/TextField/TextField';
import Button from '@Shared/Button/Button';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as GoogleLogo } from '@icons/google-logo.svg';
import { ReactComponent as AppleLogo } from '@icons/apple-logo.svg';
import './LogIn.css';
import { error as ToastError } from '@lib/utils/toast';
import { useDispatch } from 'react-redux';
import { logInEmail } from '@state/actions/authActions';
import LoaderSpinner from '@Shared/LoaderSpinner/LoaderSpinner';
import AtticusLogo from '@assets/images/atticus/logo/atticus-logo.png';

const LogIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const attemptLogin = async (e) => {
    setLoading(true);
    e.preventDefault();

    const response = await dispatch(
      logInEmail(formData.email, formData.password)
    );
    if (response?.success) {
      navigate('/dashboard');
      setLoading(false);
    } else {
      console.log('error');
      console.log(response)
      const errorMessage = response?.message || 'Error loggin in.';
      ToastError(errorMessage);
      setLoading(false)
    }
  };

  return (
    <main className="auth-page login-page">

      <div className='gradient-color-background-right'></div>

      <section className="auth-left">
        <div className='logo-container'>
          <Link to={'/dashboard'}>
            <div className='logo-dashboard'>
              <img src={AtticusLogo} alt="JuristAI" />
              <div className="logo-head">
                <h4>Fedcrim AI
                  <span>Generate AI Law Briefs</span>

                </h4>
              </div>
            </div>
          </Link>
        </div>


        <h2>Fedcrim.ai is the future of criminal defense.</h2>
        <div className='gradient-color-background'></div>
      </section>
      <section className="auth-right">
        <div className="auth-form-container">
          <form>
            <h1>Welcome Back!</h1>
            <p>Just a few quick details and you're in.</p>
            <TextField
              placeholder="Email address"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            <TextField
              placeholder="Password"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
            />
            <Link to="/forgot-password" className="forgot-password-link">
              Forgot password?
            </Link>
            <Button type="submit" onClick={attemptLogin} className="button--primary login-page-button" disabled={loading}>
              {loading
                ? <LoaderSpinner messages={''} hideText={false} color='var(--color-black)' width={20} />
                : 'Sign in'
              }
            </Button>
            <p>or sign in with</p>
            <button className="third-party-auth-button" onClick={(e) => e.preventDefault()}>
              <GoogleLogo />
              <p>Continue with Google</p>
            </button>
            <button className="third-party-auth-button" onClick={(e) => e.preventDefault()}>
              <AppleLogo />
              <p>Continue with Apple</p>
            </button>
            <p className='auth-form-footer-text'>
              Don't have an account? <Link to="/signup">Sign up</Link>
            </p>
          </form>
        </div>
      </section>
    </main>
  );
};

export default LogIn;
